import { useCallback, useEffect, useMemo } from "react";
import { filterTouchedFields } from "../../utils/filterTouchedFields";
import { useUpdateContainersMutation } from "../../../../store/newApi/containers/containers";

export const useHandleUpdate = ({ entityIds, handleClose, refetchData, ...methods }) => {
    const isSingleUpdate = useMemo(() => entityIds.length === 1, [entityIds]);
    const { formState: { touchedFields }, reset: resetForm } = methods;
    const [updateContainer, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdateContainerMutation }] = useUpdateContainersMutation();

    useEffect(() => {
        if (isUpdateSuccess) {
            handleClose();
            resetUpdateContainerMutation();
            resetForm();
            refetchData();
            // TODO: Вызвать нотификашку
        }

        return () => {
            resetForm();
        }
    }, [isUpdateSuccess, handleClose, resetUpdateContainerMutation, resetForm, refetchData]);

    const handleUpdate = useCallback((value) => { 
        const touchedFieldValues = filterTouchedFields(value, touchedFields);
        const payload = {
            container_ids: entityIds,
            orgId: touchedFieldValues.client,
            terminalId: touchedFieldValues.terminal,
            size: +value.size,
            type: +value.type,
            booking: touchedFieldValues.bookingNumber,
            is_filled: touchedFieldValues.isFilled,
            is_dangerous: touchedFieldValues.isDangerous,
            is_damaged: touchedFieldValues.isDamaged,
            is_blocked: touchedFieldValues.isBlocked,
            line: touchedFieldValues.line,
            iso_code: value.isoCode ? touchedFieldValues.isoCode : null,
            location: touchedFieldValues.location,
            stock: touchedFieldValues.stock,
        }
        if (isSingleUpdate)
            payload['container_number'] = touchedFieldValues.containerNumber
        updateContainer(payload)
    }, [entityIds, updateContainer, touchedFields, isSingleUpdate]);

    return {
        handleUpdate,
        isUpdateSuccess,
        isUpdateLoading
    }
}