import { useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import './IsoCodeInputAutoComplete.scss';
import { useLazyGetIsoCodesListQuery } from 'store/newApi/isoCodes/isoCodes';
import { InputAutocompleteAsync } from 'newComponents/Inputs/InputAutocomplete/InputAutocompleteAsync';

type IsoCodeInputAutoCompleteProps = {
	value: any;
	onChange: (value: any) => void;
	labelText?: string;
};

export const IsoCodeInputAutoComplete = ({ value, onChange, labelText, ...rest }: IsoCodeInputAutoCompleteProps) => {
	// @ts-ignore
	const [trigger, { options = [], loading }] = useLazyGetIsoCodesListQuery({
		selectFromResult: ({ data }) => ({
			options: data?.results?.map((t) => ({ label: t.iso_code, id: t.id })),
		}),
	});
	const handleSearch = useCallback(
		(value) => {
			trigger({ search: value });
		},
		[value, trigger],
	);

	useEffect(() => {
		if (value) {
			trigger({ iso_id: value });
		}
	}, [value, trigger]);

	return (
		<Box sx={{ width: '100%', display: 'flex', gap: '8px' }}>
			<InputAutocompleteAsync
				{...rest} 
				labelText={labelText}
				value={value}
				fetchFn={handleSearch}
				placeholder="Выберите или введите вручную"
				options={options}
				loading={loading}
				onChange={onChange}
			/>
		</Box>
	);
};
