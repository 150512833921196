import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material';
import { account, accountGridItemHalf } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import Preloader from '../../../../components/Preloader/Preloader';
import { useLazyGetServiceOrdersQuery } from '../../../../store/newApi/service/service.js';

import { formatPrice } from '../../../../helpers/formatText.js';
import { useDispatch, useSelector } from 'react-redux';
import { FILTER_TYPES } from '../../../../config/filter';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/repairs.js';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { useNavigate } from 'react-router-dom';
import { isClient } from '../../../../shared/auth/isClient.js';
import { RepairActCard } from '../../../../entities/repair/ui/RepairActCard/RepairActCard';
import { Filters } from '../../../../shared/ui/Filters/Filters';

function AccountEmplRepair() {
	const dispatch = useDispatch();
	const filters = useSelector((state) => state['repairsRtk']?.filter) ?? {};
	const [trigger, { data, isLoading }] = useLazyGetServiceOrdersQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const [filtering, setFiltering] = useState(false);
	const navigate = useNavigate();

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ page: 1, page_size: 20 });
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		trigger({ ...filters, page: 1, page_size: 20, uniq: Math.random() });
		setCurrentPage(1);
		setFiltering(true);
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 20 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data.results];
				} else return [...prevData, ...data.results];
			});
			setFetching(false);
		}
	}, [data]);

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	const handleNavigateToCard = useCallback(
		(id) => {
			if (isClient()) navigate(`/accountClient/createRepair/${id}`);
			else navigate(`/accountEmpl/createRepair/${id}`);
		},
		[navigate],
	);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Ремонтные работы'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium" onClick={() => navigate('/accountEmpl/createRepair')}>
						Добавить акт
					</Button>,
				]}
				btnMobile={[
					<Button variant="contained" color="primary" size="small" onClick={() => navigate('/accountEmpl/createRepair')}>
						Добавить
					</Button>,
				]}
				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'status',
								title: 'Статус',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 0, label: 'Создан' },
									{ value: 1, label: 'Требует подтверждения' },
									{ value: 2, label: 'Подтвержден' },
									{ value: 3, label: 'В работе' },
									{ value: 4, label: 'Завершен' },
								],
							},
							{
								name: 'terminal_ids',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							{
								name: ['total_price_gte', 'total_price_lte'],
								title: 'Цена',
								text: 'цена',
								type: FILTER_TYPES.INPUTNUMRANGE,
								elements: [],
							},
							{
								name: ['task_count_gte', 'task_count_lte'],
								title: 'Количество работ',
								text: 'кол-во',
								type: FILTER_TYPES.INPUTNUMRANGE,
								elements: [],
							},
						]}
					/>
				}
			/>

			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container sx={account.gridContainer}>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemHalf(isDesktop)} key={index} onClick={() => handleNavigateToCard(card?.id)}>
									<RepairActCard
										id={card?.id}
										status={card?.status_display}
										colorStatus={card?.status_display == 'В работе' ? 'warning' : 'primary'}
										client={card?.client}
										containerNumber={card?.container}
										terminal={card?.terminal}
										tasksCount={card?.tasks_count}
										totalPrice={formatPrice(card?.total_price)}
									/>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplRepair;
