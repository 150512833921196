import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { getExportExcel } from '../../../../store/rtkSlices/containers.js';
import { useDispatch } from 'react-redux';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { useGetLinesListQuery, useLazyGetContainersListQuery } from '../../../../store/newApi/containers/containers';
import { FILTER_TYPES } from '../../../../config/filter';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/containers.js';
import { Button } from '@mui/material';
import BtnDropdown from '../../../../newComponents/BtnDropdown/BtnDropdown';
import { ContainerForm } from '../../../../newComponents/Form/ContainerForm/ContainerForm'; 
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { Filters } from '../../../../shared/ui/Filters/Filters';
import { ContainersCardsList } from '../../../../entities/container/ui/ContainersCardList/ContainersCardList';
import { SwitchContentViewType } from '../../../../shared/ui/SwitchContentViewType/SwitchContentViewType';
import { LINK_CONTAINER_BY_ID } from '../../../../shared/containers/pageUrls';
import { useGetContainerTableColumns } from 'shared/ui/Tables/hooks/columns/useGetContainerTableColumns';
import { isEmpl } from 'shared/auth/isEmpl';
import { CONTAINER_PARAMETER_FILTER, CONTAINER_SIZE, CONTAINER_STATUS_FILTER, CONTAINER_TYPE } from 'shared/containers/constants';

function AccountEmplContainers() {
	const dispatch = useDispatch();
	const [fetchContainers, { data, isLoading, isFetching, isError }] = useLazyGetContainersListQuery({});

	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});
	const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
	const [isImportActive, setIsImportActive] = useState(false);
	const [viewType, setViewType] = useState('table');

	// колонки и расположение
	const { columnsList, columnOrdering } = useGetContainerTableColumns();

	const handleCreate = useCallback(() => {
		setRowSelection({});
		setIsCreateFormOpen(true);
	}, []);

	const handleUpdate = useCallback(() => {
		setIsCreateFormOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		setRowSelection({});
		setIsCreateFormOpen(false);
		setIsImportActive(false);
	}, [setRowSelection]);

	const handleExportExcel = () => {
		let viewCols = '';
		if (Object.keys(rowSelection).length !== 0) {
			viewCols += 'container_ids_in=' + Object.keys(rowSelection).join(',');
		}
		dispatch(getExportExcel(viewCols));
	};

	const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const menuItems = [
		{
			text: 'Вручную',
			onClick: handleCreate,
		},
		{
			text: 'Импорт из excel',
			onClick: () => {
				setIsImportActive(true);
				setIsCreateFormOpen(true);
				setRowSelection({});
			},
		},
	];

	const { data: dataLines } = useGetLinesListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataLines)) {
			const newElements = dataLines.map((item) => ({
				value: item.id,
				label: item.line_name,
			}));
			setElements(newElements);
		}
	}, [dataLines]);

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elementsTerminals, setElementsTerminals] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElementsTerminals(newElements);
		}
	}, [dataTerminals]);

	const handleSwitchView = useCallback((viewType) => {
		setViewType(viewType);
		handleResetFilter();
	}, []);

	const transformOptions = (values) => {
		return Object.values(values).map(({ value, id }) => ({
			label: String(value),
			value: String(id),
		}));
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Контейнеры'}
				btnDesktop={[
					<SwitchContentViewType onClick={handleSwitchView} defaultViewType={viewType} />,
					selectedRows.length > 0 && (
						<Button variant="contained" color="secondary" size="medium" onClick={handleUpdate}>
							Изменить
						</Button>
					),
					<BtnDropdown mainButtonText="Добавить контейнеры" dropdownItems={menuItems} />,
					<Button variant="contained" color="primary" size="medium" onClick={handleExportExcel}>
						Экспортировать
					</Button>,
				]}
				btnMobile={[
					<SwitchContentViewType onClick={handleSwitchView} defaultViewType={viewType} />,
					selectedRows.length > 0 && (
						<Button variant="contained" color="secondary" size="small" onClick={handleUpdate}>
							Изменить
						</Button>
					),
					<BtnDropdown mainButtonText="Добавить" dropdownItems={menuItems} size="small" />,
				]}
				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'status',
								title: 'Статус',
								type: FILTER_TYPES.CHECKBOX,
								elements: CONTAINER_STATUS_FILTER,
							},
							{
								name: 'terminal_id',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elementsTerminals,
							},
							isEmpl() && {
								name: 'client_id',
								title: 'Клиент',
								type: FILTER_TYPES.INPUTDROPDOWNCLIENT,
							},
							{
								name: 'line',
								title: 'Линия',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							{
								name: 'size',
								title: 'Размер',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: transformOptions(CONTAINER_SIZE),
							},
							{
								name: 'type',
								title: 'Тип',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: transformOptions(CONTAINER_TYPE),
							},
							{
								name: ['is_damaged', 'is_dangerous', 'is_filled'],
								title: 'Характеристики',
								type: FILTER_TYPES.SWITCH,
								elements: CONTAINER_PARAMETER_FILTER,
							},

							{
								name: ['date_from', 'date_to'],
								title: 'Дата создания',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
						]}
					/>
				}
			/>

			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					{viewType === 'block' && (
						<ContainersCardsList
							onItemClick={(id) => {
								const url = LINK_CONTAINER_BY_ID(id);
								window.open(url, '_blank');
							}}
						/>
					)}
					{viewType === 'table' && (
						<TableComponent
							rowSelection={rowSelection}
							setRowSelection={setRowSelection}
							fetchData={fetchContainers}
							isLoading={isLoading}
							isFetching={isFetching}
							isError={isError}
							data={data}
							columnsList={columnsList}
							columnOrdering={columnOrdering}
							filterName="containersRtk"
							resetFilters={handleResetFilter}
						/>
					)}
					<ContainerForm
						isOpen={isCreateFormOpen}
						handleClose={handleClose}
						entityIds={selectedRows}
						refetchData={() => fetchContainers({ page: 1, page_size: 30 })}
						isImport={isImportActive}
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplContainers;
