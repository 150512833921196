import * as z from 'zod';
import { isClient } from '../../../shared/auth/isClient';

export const createContainerSchema = z.object({
	containerNumber: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	terminal: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	client: isClient() ? z.number().optional() : z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	size: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	type: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	line: z.number().optional(),
	bookingNumber: z.string().optional(),
	isFilled: z.boolean().optional(),
	isDamaged: z.boolean().optional(),
	isDangerous: z.boolean().optional(),
	isBlocked: z.boolean().optional(),
	isoCode: z.any().optional(),
	stock: z.string().optional(),
	location: z.string().optional(),
});

export const updateContainerSchema = z.object({
	containerNumber: z.string().optional(),
	terminal: z.number().optional(),
	client: z.number().optional(),
	size: z.any().optional(),
	type: z.any().optional(),
	line: z.number().optional(),
	bookingNumber: z.string().optional(),
	isFilled: z.boolean().optional(),
	isDamaged: z.boolean().optional(),
	isDangerous: z.boolean().optional(),
	isBlocked: z.boolean().optional(),
	isoCode: z.any().optional(),
	stock: z.string().optional(),
	location: z.string().optional(),
});

export const importContainerSchema = z.object({
	terminal: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	client: isClient() ? z.number().optional() : z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
});
