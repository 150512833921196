import { useEffect } from 'react';

export const useInitFormState = ({ data, setOptionsAct, ...methods }) => {
	const { setValue } = methods;

	useEffect(() => {
		if (data) {
			if (data.line) setValue('line', data.line);
			if (data.container_number) setValue('containerNumber', data.container_number);
			if (data.size) setValue('size', `${data.size}`);
			if (data.type || data.type == 0) setValue('type', `${data.type}`);
			if (data.client) setValue('client', data.client);
			if (data.booking) setValue('bookingNumber', data.booking);
			setValue('isBlocked', data.is_blocked);
			setValue('isFilled', data.is_filled);
			setValue('isDangerous', data.is_dangerous);
			setValue('isDamaged', data.is_damaged);
			if (data.terminal) setValue('terminal', data.terminal);
			if (data.iso_code) setValue('isoCode', data.iso_code);
			if (data.location) setValue('location', data.location);
			if (data.stock) setValue('stock', data.stock);
			if (data.note) setValue('note', data.note);

			if (data.entry_reports || data.repairs) {
				const entryReportsOptions =
					data.entry_reports?.map((report) => ({
						label: `Акт осмотра №${report.id}`,
						id: report.id,
						type: 'entry_reports',
					})) || [];

				const repairsOptions =
					data.repairs?.map((repairId) => ({
						label: `Акт ремонта №${repairId}`,
						id: repairId,
						type: 'repairs',
					})) || [];

				const combinedOptions = [...entryReportsOptions, ...repairsOptions];
				setOptionsAct(combinedOptions);
			}
		}
	}, [data, setValue, setOptionsAct]);
};
