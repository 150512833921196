export const inputText = {
	textField: {
		backgroundColor: (theme) => theme.palette.input.background,
		borderRadius: '8px',
		border: '1px solid',
		borderColor: (theme) => theme.palette.input.borderColor,
		padding: '9px 16px',
		color: (theme) => theme.palette.input.color,
		transition: 'background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease',
		input: {
			padding: '0px !important',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.04px',
			color: (theme) => theme.palette.input.color,
		},

		// '& .Mui-disabled': {
		// 	backgroundColor: '#8E8E93',
		// 	color: '#ffffff',
		// 	border: '1px solid #8E8E93',
		// },

		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&:hover': {
			backgroundColor: (theme) => theme.palette.input.backgroundActive,
			borderColor: (theme) => theme.palette.input.borderColorActive,
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: (theme) => theme.palette.input.borderColorActive,
		},
		'& input::placeholder': {
			color: (theme) => theme.palette.input.color,
			opacity: 1,
		},

		'& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},

		'& input[type=number]': {
			'-moz-appearance': 'textfield',
		},

		'& input:-webkit-autofill': {
			WebkitTextFillColor: (theme) => theme.palette.text.secondary,
			caretColor: (theme) => theme.palette.text.secondary,
			backgroundColor: 'transparent !important',
			WebkitBoxShadow: '0 0 0 1000px transparent inset !important',
			transition: 'background-color 9999s ease-out, color 9999s ease-out',
		},

		'& .MuiInputBase-root': {
			padding: '0px !important',
		},
	},
	icon: {
		color: (theme) => theme.palette.input.color,
		cursor: 'pointer',
	},
	valueTextField: {
		borderColor: (theme) => theme.palette.input.borderColorActive,
		backgroundColor: (theme) => theme.palette.input.backgroundActive,
		input: {
			padding: '0px !important',
			fontWeight: '500',
			fontSize: '14px',
			lineHeight: '20px',
			letterSpacing: '0.04px',
			color: (theme) => theme.palette.input.colorValue,
		},
	},
};
