import { InputAutocompleteAsync } from '../../Inputs/InputAutocomplete/InputAutocompleteAsync';
import { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { useLazyGetEntriesListQuery } from 'store/newApi/entries/entries';

export const EntrypassInputAutocomplete = ({ value, onChange, searchParams = {}, ...rest }) => {
	const [trigger, { clientsOptions = [], isLoading: loadingClients }] = useLazyGetEntriesListQuery({
		selectFromResult: ({ data, isLoading }) => ({
			clientsOptions: data?.results?.map((t) => ({ label: `${t.id}`, id: t.id })),
            isLoading
		}),
	});
	const handleSearchClients = useCallback(
		(value) => {
			trigger({ search: value, ...searchParams });
		},
		[searchParams],
	);

	return (
		<Box sx={{ width: '100%', display: 'flex', gap: '8px' }}>
			<InputAutocompleteAsync
				{...rest}
                labelText="Пропуск"
				value={value}
				fetchFn={handleSearchClients}
				placeholder="Выберите или введите вручную"
				options={clientsOptions}
				loading={loadingClients}
				onChange={onChange}
			/>
		</Box>
	);
};
