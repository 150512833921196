import { useCallback, useEffect, useMemo } from "react"
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { importContainerFields } from "./utils";
import { LinkedContainerFields } from "../../containers/types";
import { useBulkCreateContainersMutation, useGetLinesListQuery } from "../../../store/newApi/containers/containers";
import { CONTAINER_SIZES_BY_VALUE, CONTAINER_TYPE } from "../../containers/constants";

export type ImportContainersProps = {
    isOpen: boolean;
    disableNavigation: boolean;
    toggleIsOpen: (flag: boolean) => void;
    importedCallback: (containerIds: number[]) => void;
    defaultCreateFields: Partial<LinkedContainerFields>;
}

export const ImportContainers = ({
    isOpen,
    toggleIsOpen,
    defaultCreateFields,
    importedCallback = () => {},
    disableNavigation
}: ImportContainersProps) => {
    const [createContainers, { data: createdContainers, isSuccess, reset: resetImport }] = useBulkCreateContainersMutation();
    const { data: lines = [], isFetching: isFetchingLines } = useGetLinesListQuery({});

    const createdContainerIds = useMemo<number[]>(() => createdContainers?.map(c => c.id) || [], [createdContainers]);

    const handleImportSubmit = useCallback((data) => {
        const { terminalId, orgId } = defaultCreateFields;
		const newContainers = data?.validData?.map((item) => ({
			...item,
            line: lines.find(l => l.line_name === item.line)?.id ?? null,
			size: CONTAINER_SIZES_BY_VALUE[item?.size] ?? null,
			type: CONTAINER_TYPE[item.type?.replace(/\s/g, '')]?.id ?? null,
		}));
        createContainers({terminalId, orgId, containers: newContainers});
    }, [defaultCreateFields, lines, createContainers]);

    useEffect(() => {
        if (isSuccess) {
            importedCallback(createdContainerIds)
            resetImport()
        }
    }, [resetImport, isSuccess, importedCallback, createdContainerIds]);

    return (
        <div>
            <ReactSpreadsheetImport
                isOpen={isOpen}
                isNavigationEnabled={!isFetchingLines && !disableNavigation}
                onClose={() => toggleIsOpen(false)}
                onSubmit={handleImportSubmit}
                fields={importContainerFields}
                translations={{
                    uploadStep: {
                        title: 'Загрузка таблицы контейнеров',
                        manifestTitle: 'Данные, которые мы ожидаем:',
                        manifestDescription: '(На следующих шагах у вас будет возможность переименовать или удалить столбцы.)',
                        dropzone: {
                            title: 'Загрузить .xlsx, .xls or .csv file',
                            errorToastDescription: 'загрузка отклонена',
                            activeDropzoneTitle: 'Drop file here...',
                            buttonTitle: 'Выберите файл',
                            loadingTitle: 'Обработка...',
                        },
                        // selectSheet: {
                        // 	title: 'Select the sheet to use',
                        // 	nextButtonTitle: 'Далее',
                        // 	backButtonTitle: 'Back',
                        // },
                    },
                    selectHeaderStep: {
                        title: 'Выбор строк таблицы',
                        nextButtonTitle: 'Далее',
                    },
                    matchColumnsStep: {
                        title: 'Сопоставление столбцов',
                        userTableTitle: 'Ваша таблица',
                        templateTitle: 'Итоговая таблица',
                        nextButtonTitle: 'Далее',
                    },
                    validationStep: {
                        title: 'Проверка данных',
                        discardButtonTitle: 'Отменить выбранные строки',
                        filterSwitchTitle: 'Показывать только строки с ошибками',
                        nextButtonTitle: 'Подтвердить импорт',
                    },
                    alerts: {
                        confirmClose: {
                            headerTitle: 'Выйти из импорта',
                            bodyText: 'Вы уверены? Ваша текущая информация не будет сохранена.',
                            cancelButtonTitle: 'Отмена',
                            exitButtonTitle: 'Выйти',
                        },
                        submitIncomplete: {
                            headerTitle: 'Обнаружены ошибки',
                            bodyText: 'Еще есть строки, содержащие ошибки. Строки с ошибками будут игнорироваться при отправке.',
                            bodyTextSubmitForbidden: 'Есть еще несколько строк, содержащих ошибки.',
                            cancelButtonTitle: 'Отмена',
                            finishButtonTitle: 'Отправить',
                        },
                        unmatchedRequiredFields: {
                            headerTitle: 'Не все столбцы совпали',
                            bodyText: 'Есть обязательные столбцы, которые не сопоставляются или игнорируются. Вы хотите продолжить?',
                            listTitle: 'Столбцы не совпадают:',
                            cancelButtonTitle: 'Отмена',
                            continueButtonTitle: 'Продолжить',
                        },
                        toast: {
                            error: 'Ошибка',
                        },
                    },
                }}
            />
        </div>
    )
}