import { useLazyGetTerminalsListQuery } from '../../../store/newApi/terminals/terminals';
import { useCallback, useEffect } from 'react';
import { InputAutocompleteAsync } from 'newComponents/Inputs/InputAutocomplete/InputAutocompleteAsync';

export const TerminalInputAutocomplete = ({ clientId = '', value, onChange, ...rest }) => {
	const [trigger, { terminalOptions = [] }] = useLazyGetTerminalsListQuery({
		selectFromResult: ({ data }) => ({
			terminalOptions: data?.map((e) => ({ label: e.name, id: e.id })) || [],
		}),
	});

	const handleSearchTerminals = useCallback(
		(value) => {
			trigger({ search: value });
		},
		[value, trigger],
	);

	useEffect(() => {
		trigger();
	}, []);

	useEffect(() => {
		if (clientId) {
			trigger({ client_id: clientId });
		}
	}, [trigger, clientId]);

	return (
		<InputAutocompleteAsync
			{...rest}
			value={value}
			fetchFn={handleSearchTerminals}
			placeholder="Выберите или введите вручную"
			options={terminalOptions}
			onChange={onChange}
		/>
	);
};
