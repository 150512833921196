import { Button, useMediaQuery } from '@mui/material';
import Header from '../../../../../newComponents/Header/Header';
import styles from './AccountEmplRepairCreate.module.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChooseContainerStep } from './components/ChooseContainerStep/ChooseContainerStep';
import { ChooseRepairStep } from './components/ChooseRepairStep/ChooseRepairStep';
import { ChooseServicesStep } from './components/ChooseServicesStep/ChooseServicesStep';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useExportExcelRepairOrderMutation, useLazyGetServiceOrdersByIdQuery, useUpdateRepairOrderMutation } from '../../../../../store/newApi/service/service';
import { useLazyGetContainersListQuery } from '../../../../../store/newApi/containers/containers';
import { isClient } from '../../../../../shared/auth/isClient';
import BtnDropdown from '../../../../../newComponents/BtnDropdown/BtnDropdown';
import { useGetPermissions } from '../../../../../shared/auth/hooks/useGetPermissions';
import { getReparStatusById, REPAIR_STATUSES } from '../../../../../shared/repairs/constants';
import { Preloader } from '../../../../../components';
import { RepairCommentStep } from './components/RepairCommentStep/RepairCommentStep';
import { PageTabs } from '../../../../../shared/ui';

export const AccountEmplRepairCreate = () => {
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
    const location = useLocation();
    const navigate = useNavigate();
    const { repairOrderId } = useParams();
    const [params] = useSearchParams();
    const step = params.get('step');
    const repairGroupId = params.get('repairGroupId');

    const { isRemontnik } = useGetPermissions();

    const [fetchServiceOrder, {
        data: serviceOrder,
        isFetching: isFetchingServiceOrder,
        isLoading: isLoadingServiceOrder
    }] = useLazyGetServiceOrdersByIdQuery();

    const [getContainers, { container }] = useLazyGetContainersListQuery({
        selectFromResult: ({ data }) => ({
			container: data?.results ? data?.results[0] : null,
		}),
    });
    const [updateRepairOrder, { data, isSuccess: isUpdateOrderSuccess, reset: resetUpdateOrder }] = useUpdateRepairOrderMutation();

    const [currentStep, setCurrentStep] = useState(0);
    const [containerInfo, setContainerInfo] = useState(null);
    const [serviceOrderInfo, setServiceOrder] = useState(null);
    const [repairGroupStatus, setRepairGroupStatus] = useState(null);
    const [photoFix, setPhotoFix] = useState([]);

    const isEditMode = useMemo(() => !!repairOrderId, [repairOrderId]);

    const isReadyToAccept = useMemo(() => {
        return serviceOrder?.repair_group?.filter(rp => rp.status === 1 || rp.status === 3)?.length === 0 && isClient();
    }, [serviceOrder]);

    const hideAcceptButton = useMemo(() => {
        return !isClient() || serviceOrder?.status >= 4;
    }, [serviceOrder]);

    const refetchServiceOrder = useCallback(() => {
        fetchServiceOrder({ id: repairOrderId })
    }, [repairOrderId, fetchServiceOrder]);

    useEffect(() => {
        refetchServiceOrder()
    }, [refetchServiceOrder]);

    // Если редактируем serviceOrder по id
    useEffect(() => {
        if (serviceOrder) {
            getContainers({ container_number: serviceOrder.container })
            setServiceOrder(serviceOrder);
        }
    }, [serviceOrder, getContainers, setServiceOrder]);

    // Если пришла инфа про контейнер
    useEffect(() => {
        if (container) {
            setContainerInfo(container);
        }
    }, [container])

    // Если передали шаг из url
    useEffect(() => {
        if (step)
            setCurrentStep(+step);
    }, [step]);

    const handleChangeStep = (step) => {
        navigate(`${location.pathname}?step=${step}`)
    }

    const handleGoToServicesStep = (repairGroupId) => {
        navigate(`${location.pathname}?step=2&repairGroupId=${repairGroupId}`)
    }

    const handleGoToRepairsListPage = () => {
        if (isClient())
            navigate('/accountClient/repair')
        else
            navigate('/accountEmpl/repair')
    }

    const handleAcceptOrder = useCallback(() => {
        updateRepairOrder({
            id: repairOrderId,
            base_price: serviceOrder.base_price,
            total_price: serviceOrder.total_price,
            status: 2,
            comment: serviceOrder.comment
        });
    }, [serviceOrder, repairOrderId, updateRepairOrder]);

    const hideTabs = useMemo(() => {
        return currentStep === 2;
    }, [currentStep]);

    const showChangeReparStatusButton = useMemo(() => {
        return currentStep === 2 && isRemontnik
    }, [currentStep, isRemontnik]);

    const handleChangeStatus = useCallback((statusId) => {
        setRepairGroupStatus(statusId)
    }, []);

    useEffect(() => {
        if (isUpdateOrderSuccess) {
            handleGoToRepairsListPage();
            resetUpdateOrder();
        }
    }, [isUpdateOrderSuccess, resetUpdateOrder]);

    const [exportToExcel] = useExportExcelRepairOrderMutation();
    const handlExport = () => {
        if (serviceOrderInfo?.id) {
            exportToExcel({ id: serviceOrderInfo?.id });
        }
    };

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return <ChooseContainerStep
                    refetchServiceOrder={refetchServiceOrder}
                    serviceOrderInfo={serviceOrderInfo} 
                    onSetContainer={setContainerInfo}
                    containerInfo={containerInfo}
                    setServiceOrder={setServiceOrder}
                    onNext={() => handleChangeStep(1)}
                    onBack={handleGoToRepairsListPage}
                    isEditMode={isEditMode}
                />
            case 1:
                return <ChooseRepairStep
                    onChooseRepair={handleGoToServicesStep}
                    refetchRepairOrder={refetchServiceOrder}
                    repairOrderData={serviceOrder}
                    onNext={() => handleChangeStep(3)}
                    onBack={() => handleChangeStep(0)}
                    onCancel={handleGoToRepairsListPage}
                />
            case 2:
                return <ChooseServicesStep
                    repairGroupId={repairGroupId}
                    containerInfo={containerInfo}
                    refetchRepairOrder={refetchServiceOrder}
                    onBack={() => handleChangeStep(1)}
                    changedRepairGroupStatus={repairGroupStatus}
                    handleChangeRepairGroupStatus={handleChangeStatus}
                    handleSetPhotoFix={setPhotoFix}
                />
            case 3:
                return <RepairCommentStep
                    onBack={() => handleChangeStep(1)}
                    onCancel={handleGoToRepairsListPage}
                    repairOrderId={repairOrderId}
                    serviceOrderInfo={serviceOrderInfo}
                />
            default:
                return null;
        }
    }

    const isloadingPage = useMemo(() => isFetchingServiceOrder || isLoadingServiceOrder, [isFetchingServiceOrder, isLoadingServiceOrder])

    return <div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Header
            text={'Ремонт'}
            btnDesktop={[
                !hideAcceptButton && <Button disabled={!isReadyToAccept} variant="contained" color="primary" size="medium" onClick={handleAcceptOrder}>
                    Подтвердить акт
                </Button>,
                showChangeReparStatusButton && <BtnDropdown
                    mainButtonText={getReparStatusById(repairGroupStatus)?.title || "Статус"}
                    dropdownItems={[
                        {
                            text: REPAIR_STATUSES.ON_REPAIR.title,
                            onClick: () => handleChangeStatus(REPAIR_STATUSES.ON_REPAIR.id),
                        },
                        {
                            text: REPAIR_STATUSES.REPAIRED.title,
                            onClick: () => handleChangeStatus(REPAIR_STATUSES.REPAIRED.id),
                            disabled: photoFix.length === 0
                        },
                    ]}
                />,
                <Button variant="contained" color="primary" size="medium" onClick={handlExport}>
                        Экспорт 
                        </Button>,
            ]}
            btnMobile={[
                isClient() && <Button disabled={!isReadyToAccept} variant="contained" color="primary" size="small" onClick={handleAcceptOrder}>
                    Подтвердить акт
                </Button>,
                <Button variant="contained" color="primary" size="medium" onClick={handlExport}>
                Экспорт
            </Button>,
            ]}
        />
        <div className={styles.content}>
            <PageTabs
                tabs={[
                    { title: 'Контейнер', step: 0 },
                    { title: 'Ремонтные работы', step: 1, disabled: !serviceOrderInfo },
                    { title: 'Комментарии', step: 3, disabled: !serviceOrderInfo },
                ]}
                onTabClick={handleChangeStep}
                currentStep={currentStep}
                hidden={hideTabs}
            />
            <div className={styles.steps}>
                {isloadingPage && <Preloader bg={'true'} />}
                {!isloadingPage && renderStep()}
            </div>
        </div>
    </div>
}