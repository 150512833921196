import { useCallback } from 'react';
import { useUpdateContainersMutation } from '../../../../../../store/newApi/containers/containers';
import { filterTouchedFields } from '../../../../../../newComponents/Form/utils/filterTouchedFields';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';

export const useHandleUpdate = ({ containerId, refetch, ...methods }) => {
	const { showNotificaton } = useNotify();
	const {
		formState: { touchedFields },
	} = methods;
	const [updateContainer, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdateContainerMutation }] =
		useUpdateContainersMutation();

	const handleUpdate = useCallback(
		(value) => {
			const touchedFieldValues = filterTouchedFields(value, touchedFields);
			const payload = {
				container_ids: [containerId],
				container_number: touchedFieldValues.containerNumber,
				orgId: touchedFieldValues.client,
				terminalId: touchedFieldValues.terminal,
				size: +value.size,
				type: +value.type,
				booking: touchedFieldValues.bookingNumber,
				is_filled: touchedFieldValues.isFilled,
				is_dangerous: touchedFieldValues.isDangerous,
				is_damaged: touchedFieldValues.isDamaged,
				is_blocked: touchedFieldValues.isBlocked,
				line: touchedFieldValues.line,
				iso_code: value.isoCode ? touchedFieldValues.isoCode : null,
				location: touchedFieldValues.location,
				stock: touchedFieldValues.stock,
				note: touchedFieldValues.note,
			};
			updateContainer(payload).then((data) => {
				if (data.error) {
					showNotificaton({
						type: 'error',
						message: tranformErrorsToRender(data.error?.data, 'Не удалось обновить контейнер'),
					});
				} else {
					refetch({ id: containerId });
					resetUpdateContainerMutation();
					showNotificaton({
						type: 'success',
						message: 'Контейнер успешно обновлен',
					});
				}
			});
		},
		[containerId, updateContainer, touchedFields],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
