import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material';
import { account, accountGridItemThird } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLazyGetClientsListQuery } from '../../../../store/newApi/organizations/organizations.js';
import Preloader from '../../../../components/Preloader/Preloader'; 
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/clients.js';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { FILTER_TYPES } from '../../../../config/filter';
import { PAGE_CLIENT_BY_ID } from '../../../../shared/clients/pageUrls'; 
import { ClientCard } from '../../../../entities/client/ui/ClientCard/ClientCard';
import { Filters } from '../../../../shared/ui/Filters/Filters';

function AccountEmplClients() {
	const filters = useSelector((state) => state['clientsRtk']?.filter) ?? {};
	const [filtering, setFiltering] = useState(false);
	const [trigger, { data, isLoading }] = useLazyGetClientsListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const navigate = useNavigate();

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
	const isTablet = useMediaQuery((theme) => theme.breakpoints.up('tablet'));

	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	const clientParam = queryParams.get('client');

	// Самая первая загрузка

	useEffect(() => {
		if (clientParam) {
			trigger({ page: 1, page_size: 20, id: clientParam });
			setCurrentPage(1);
		} else {
			setDataCards([]);
			trigger({ page: 1, page_size: 20 });
			setCurrentPage(1);
		}
	}, [clientParam]);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		trigger({ ...filters, page: 1, page_size: 20, uniq: Math.random() });
		setCurrentPage(1);
		setFiltering(true);
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 20 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data.results];
				} else return [...prevData, ...data.results];
			});
			setFetching(false);
		}
	}, [data]);

	const dispatch = useDispatch();

	const handleNewOrg = () => {
		navigate('/accountEmpl/clients/create');
	};

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Клиенты'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium" onClick={handleNewOrg}>
						Добавить клиента
					</Button>,
				]}
				btnMobile={[
					<Button variant="contained" color="primary" size="small" onClick={handleNewOrg}>
						Добавить
					</Button>,
				]}
				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'is_manager_confirmed',
								title: 'Статус',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 1, label: 'Одобрен' },
									{ value: 2, label: 'Ожидает одобрения' },
									{ value: 3, label: 'Отклонен' },
								],
							},
							{
								name: 'terminal',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							{
								name: ['created_at_after', 'created_at_before'],
								title: 'Дата создания',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
						]}
					/>
				}
			/>
			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container sx={account.gridContainer}>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemThird(isDesktop, isTablet)} key={index}>
									<Link to={PAGE_CLIENT_BY_ID(card?.id)}>
										<ClientCard
											status={card?.is_manager_confirmed}
											colorStatus={
												card?.is_manager_confirmed == 'Одобрен'
													? 'success'
													: card?.is_manager_confirmed == 'Ожидает одобрения'
														? 'warning'
														: card?.is_manager_confirmed == 'Отклонен'
															? 'primary'
															: {}
											}
											name={card?.organization_name}
											inn={card?.inn}
											email={card?.contact_email}
										/>
									</Link>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplClients;
