import api from '../../api.js';
import { buildQueryString } from '../../../shared/ui/Filters/utils.js';

export const entries = api.injectEndpoints({
	endpoints: (builder) => ({
		getEntriesList: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								return desc ? `-${id}` : id;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;
				delete queryParams.sorting;

				return {
					url: `/api/entries/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getEntriesListComplete: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								return desc ? `-${id}` : id;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;
				delete queryParams.sorting;

				return {
					url: `/api/entries/?status=1&${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getEntry: builder.query({
			query: ({ entryId }) => {
				return {
					url: `/api/entries/${entryId}`,
					method: 'GET',
				};
			},
		}),
		updateEntry: builder.mutation({
			query: ({ ...payload }) => {
				return {
					url: `/api/entries/batch-update/`,
					method: 'PUT',
					body: [
						{
							...payload,
						},
					],
				};
			},
		}),
		createEntry: builder.mutation({
			query: ({ ...payload }) => {
				return {
					url: `/api/entries/create/`,
					method: 'POST',
					body: payload,
				};
			},
		}),
		createBatchEntry: builder.mutation({
			query: ({ ...payload }) => {
				return {
					url: `/api/entries/batch-create/`,
					method: 'POST',
					body: payload,
				};
			},
		}),
	}),
});

export const {
	useGetEntriesListQuery,
	useLazyGetEntriesListQuery,
	useLazyGetEntriesListCompleteQuery,
	useLazyGetEntryQuery,
	useGetEntryQuery,
	useUpdateEntryMutation,
	useCreateEntryMutation,
	useCreateBatchEntryMutation,
} = entries;
