import { useEffect, useState } from 'react';
import { useLazyGetTerminalsListQuery } from '../../../../../../../store/newApi/terminals/terminals';
import styles from './TabRights.module.css';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';

export const TabRights = ({ data, onBack, handleLinkTerminalRights }) => {
	const [fetchTerminals, { data: dataTerminals }] = useLazyGetTerminalsListQuery({});
	const [elementsTerminals, setElementsTerminals] = useState([]);

	useEffect(() => {
		fetchTerminals();
	}, []);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
				address: item.address,
			}));
			setElementsTerminals(newElements);
		}
	}, [dataTerminals]);

	const getStatus = (terminalId) => {
		const terminalInData = data?.terminals?.find((item) => item.id === terminalId);
		return terminalInData ? 'Доступ открыт' : 'Доступ закрыт';
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
			<Grid container className={styles.containerFormTemplate}>
				{elementsTerminals.map((card, index) => (
					<Grid
						item
						key={index}
						sx={{
							width: '100%',
							display: 'flex',
							alignItems: 'flex-start',
							justifyContent: 'space-between',
							padding: '12px',
							borderRadius: '16px',
							transition: '0.3s ease',
							border: '1px solid #5C53A7',

							'&:hover': {
								cursor: 'pointer',
								boxShadow: '0px 0px 6px #443a8e80',
								transition: '0.3s ease',
							},
						}}
						onClick={() => handleLinkTerminalRights(card.value)}
					>
						<Box>
							<Typography variant="subtitle1">Терминал: {card?.label ? card?.label : '-'}</Typography>
							<Typography variant="subtitle2">Адрес: {card?.address ? card?.address : '-'}</Typography>
						</Box>
						<Chip label={getStatus(card.value)} />
					</Grid>
				))}
			</Grid>
			<div style={{ justifyContent: 'flex-end', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
				<Button variant="outlined" color="primary" size="medium" onClick={onBack}>
					Назад
				</Button>
			</div>
		</div>
	);
};
