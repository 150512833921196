import { FILTER_TYPES } from '../../../config/filter';

export const getInitialState = (parameters) => {
	const result = {};
	parameters.forEach((filter) => {
		if (filter.type === FILTER_TYPES.CHECKBOX) {
			result[filter.name] = [];
		} else if (filter.type === FILTER_TYPES.SWITCH && Array.isArray(filter.name)) {
			filter.name.forEach((switchName) => {
				result[switchName] = false;
			});
		} else if (
			filter.type === FILTER_TYPES.INPUTNUMRANGE ||
			(filter.type === FILTER_TYPES.INPUTDATERANGE && Array.isArray(filter.name))
		) {
			filter.name.forEach((inputName) => {
				result[inputName] = '';
			});
		} else if (filter.type === FILTER_TYPES.INPUTDROPDOWN || filter.type === FILTER_TYPES.INPUTDROPDOWNCLIENT || filter.type === FILTER_TYPES.INPUTDROPDOWNCOMP || filter.type === FILTER_TYPES.INPUTDROPDOWNLEGAL || filter.type === FILTER_TYPES.INPUTDROPDOWNMANAGERS || filter.type === FILTER_TYPES.INPUTDROPDOWNORDERS) {
			result[filter.name] = '';
		} else {
			result[filter.name] = null;
		}
	});
	return result;
};

export const getElementsByFilterName = (parameters, name) => {
	const filter = parameters.find((filter) => filter.name === name);
	return filter ? filter.elements : [];
};

export const buildQueryString = (params) => {
	const queryString = Object.keys(params)
		.map((key) => {
			if (key === 'filter') {
				return null;
			}

			const value = params[key];
			// Если значение - массив, проверяем, что оно не пустое, и создаем отдельную строку для каждого элемента
			if (Array.isArray(value)) {
				if (value.length === 0) {
					return null; // Пропускаем пустые массивы
				}
				return value.map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&');
			}

			// если значение строка, то пустую пропускать
			if (typeof value === 'string') {
				if (value.trim() === '') {
					return null;
				}
				return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
			}

			// если значение boolean, то false пропускать
			if (typeof value === 'boolean') {
				if (value) {
					return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
				}
				return null;
			}

			return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
		})
		.filter(Boolean) // Убираем null значения
		.join('&');
	return queryString;
};
