import { Button, Typography } from "@mui/material"
import { DrawerModal } from "../../DrawerModal/DrawerModal"
import { Controller, useForm } from "react-hook-form";
import { useCallback, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from 'zod';
import { useHandleCreate } from "./hooks/useHandleCreate";
import { useHandleUpdate } from "./hooks/useHandleUpdate";
import { FormWrapper } from "../FormWrapper";
import { CollapseBlock } from "../../CollapseBlock/CollapseBlock";
import InputText from "../../Inputs/InputText/InputText";
import { useInitFormState } from "./hooks/useInitFormState";

const createSchema = z.object({
    registration_number: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
    trailer_number: z.string().optional(),
    driver: z.string().optional(),
});

const updateSchema = z.object({
    registration_number: z.string().optional(),
    trailer_number: z.string().optional(),
    driver: z.string().optional(),
})

export const VehicleForm = ({ isOpen, handleClose, entityIds = [] }) => {
    const isSingleUpdate = useMemo(() => entityIds.length === 1, [entityIds]);
    const isBulkUpdate = useMemo(() => entityIds.length > 1, [entityIds]);
    const isCreate = useMemo(() => entityIds.length === 0, [entityIds]);

    const resolver = useMemo(() => isCreate ? zodResolver(createSchema) : zodResolver(updateSchema), [isCreate]);

    const methods = useForm({
        defaultValues: {
            registration_number: undefined,
            trailer_number: undefined,
            driver: undefined,
        },
        resolver: resolver,
    })

    const { handleCreate, isCreateLoading } = useHandleCreate({ ...methods, handleClose });
    const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods, entityIds, handleClose });
    useInitFormState({ ...methods, ids: entityIds, isReady: isOpen });

    const { handleSubmit, control, formState: { errors } } = methods;

    const onSubmit = useCallback((value) => {
        if (isCreate)
            handleCreate(value)
        else
            handleUpdate(value)
    }, [handleCreate, handleUpdate, isCreate]);

    return (
        <DrawerModal
            isOpen={isOpen}
            handleClose={handleClose}
            actions={
                <Button isLoading={isCreateLoading || isUpdateLoading} onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
                    Сохранить
                </Button>
            }
        >
            <Typography variant="subtitle1">{isCreate ? 'Добавление' : 'Редактирование'}</Typography>
            <FormWrapper onSubmit={onSubmit} {...methods}>
                <CollapseBlock defaultOpen={true || !!errors.registration_number} title={<span>Номер</span>}>
                    <Controller
                        name="registration_number"
                        control={control}
                        render={({ field }) => (
                            <InputText
                                {...field}
                                placeholder="Номер"
                                errorMessage={errors?.registration_number?.message}
                            />
                        )}
                    />
                </CollapseBlock>
                <CollapseBlock title="Номер прицепа">
                    <Controller
                        name="trailer_number"
                        control={control}
                        render={({ field }) => (
                            <InputText
                                {...field}
                                placeholder="Номер прицепа"
                            />
                        )}
                    />
                </CollapseBlock>
                <CollapseBlock title="Водитель">
                    <Controller
                        name="driver"
                        control={control}
                        render={({ field }) => (
                            <InputText
                                {...field}
                                placeholder="Водитель"
                            />
                        )}
                    />
                </CollapseBlock>
            </FormWrapper>
        </DrawerModal>
    )
}