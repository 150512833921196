import { Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import InputText from '../../Inputs/InputText/InputText';
import { DrawerModal } from '../../DrawerModal/DrawerModal';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormWrapper } from '../FormWrapper';
import { useHandleCreate } from './hooks/useHandleCreate';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { ClientInputAutocompleteController } from '../Fields/ClientInputAutocompleteController';
import { useInitFormState } from './hooks/useInitFormState';
import { isClient } from '../../../shared/auth/isClient';
import { DateInput } from '../../Inputs/DateInput/DateInput';
import { ContainerInputAutocomplete } from '../Fields/ContainerInputAutocomplete';
import { Notification } from '../../../components';

const createSchema = z.object({
	containerNumber: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	client: isClient() ? z.number().optional() : z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	free_days_storage: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	client_tariff: isClient()
		? z.string().optional()
		: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	date_from: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	date_to: z.string().optional(),
});

const updateSchema = z.object({
	free_days_storage: z.string().optional(),
	date_from: z.string().optional(),
	date_to: z.string().optional(),
	total_price: z.string().optional(),
});

export const StorageForm = ({ isOpen, handleClose, refetchData, entityIds = [] }) => {
	// уведомление
	const [showNotification, setShowNotification] = useState(false);
	const onClose = () => {
		setShowNotification(false);
	};

	const isCreate = useMemo(() => entityIds.length === 0, [entityIds]);
	const resolver = useMemo(() => (isCreate ? zodResolver(createSchema) : zodResolver(updateSchema)), [isCreate]);

	const methods = useForm({
		defaultValues: {
			containerNumber: undefined,
			client: undefined,
			free_days_storage: '',
			total_price: undefined,
			client_tariff: '',
			date_from: undefined,
			date_to: undefined,
		},
		resolver: resolver,
	});

	const { handleCreate, isCreateLoading } = useHandleCreate({ ...methods, refetchData, setShowNotification, handleClose });
	const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods, entityIds, refetchData, handleClose });
	useInitFormState({ ...methods, ids: entityIds, isReady: isOpen });

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors },
	} = methods;
	console.log(errors);

	const onSubmit = useCallback(
		(value) => {
			if (isCreate) handleCreate(value);
			else handleUpdate(value);
		},
		[handleCreate, handleUpdate, isCreate],
	);

	const selectClientId = watch('client');

	return (
		<DrawerModal
			isOpen={isOpen}
			handleClose={handleClose}
			actions={
				<Button isLoading={isCreateLoading || isUpdateLoading} onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
					Сохранить
				</Button>
			}
		>
			<Notification
				text={'Ошибка'}
				descr={'Для этого контейнера уже создана услуга хранения'}
				onClose={onClose}
				isActive={showNotification}
				isStatus={'error'}
			/>
			<Typography variant="subtitle1">{isCreate ? 'Добавление' : 'Редактирование'}</Typography>
			<FormWrapper onSubmit={onSubmit} {...methods}>
				{isCreate && <ClientInputAutocompleteController />}
				{isCreate && (
					<CollapseBlock defaultOpen={true || !!errors?.containerNumber} title={<span>№ Контейнера</span>}>
						<Controller
							name="containerNumber"
							control={control}
							render={({ field }) => (
								<>
									<ContainerInputAutocomplete
										{...field}
										disabled={!selectClientId}
										searchParams={selectClientId && { client_id: selectClientId }}
									/>
									{errors?.containerNumber && (
										<Typography color="error" variant="caption">
											{errors?.containerNumber?.message}
										</Typography>
									)}
								</>
							)}
						/>
					</CollapseBlock>
				)}
				{isCreate && (
					<CollapseBlock title="Тариф клиента" defaultOpen={true || !!errors?.client_tariff}>
						<Controller
							name="client_tariff"
							control={control}
							render={({ field }) => (
								<InputText
									{...field}
									type="number"
									placeholder="Тариф клиента"
									errorMessage={errors?.client_tariff?.message}
								/>
							)}
						/>
					</CollapseBlock>
				)}
				{!isCreate && (
					<CollapseBlock title="Стоимость" defaultOpen={true || !!errors?.total_price}>
						<Controller
							name="total_price"
							control={control}
							render={({ field }) => (
								<InputText {...field} type="number" placeholder="Стоимость" errorMessage={errors?.total_price?.message} />
							)}
						/>
					</CollapseBlock>
				)}
				<CollapseBlock title="Бесплатные дни" defaultOpen={true || !!errors?.free_days_storage}>
					<Controller
						name="free_days_storage"
						control={control}
						render={({ field }) => (
							<InputText
								{...field}
								type="number"
								placeholder="Бесплатные дни"
								errorMessage={errors?.free_days_storage?.message}
							/>
						)}
					/>
				</CollapseBlock>
				<CollapseBlock title="Дата начала" defaultOpen={true || !!errors?.date_from}>
					<Controller
						name="date_from"
						control={control}
						render={({ field }) => (
							<>
								<DateInput {...field} onChange={(value) => setValue('date_from', value, { shouldTouch: true })} />
								{errors?.date_from && (
									<Typography color="error" variant="caption">
										{errors?.date_from?.message}
									</Typography>
								)}
							</>
						)}
					/>
				</CollapseBlock>
				<CollapseBlock title="Дата окончания">
					<Controller
						name="date_to"
						control={control}
						render={({ field }) => (
							<DateInput {...field} onChange={(value) => setValue('date_to', value, { shouldTouch: true })} />
						)}
					/>
				</CollapseBlock>
			</FormWrapper>
		</DrawerModal>
	);
};
