import { configureStore } from '@reduxjs/toolkit';
import publicFormReducer from './slices/publicFormSlice';
import containerAcceptanceReducer from './slices/containerAcceptanceSlice';
import loginReducer from './slices/loginSlice';
import sideBarReducer from './slices/sideBarSlice';
import meReducer from './slices/meSlice';
import api from './api.js';
import containers from './rtkSlices/containers';
import entries from './rtkSlices/entries';
import orders from './rtkSlices/orders';
import entryreports from './rtkSlices/entryreports';
import storage from './rtkSlices/storage.js';
import repairs from './rtkSlices/repairs.js';
import cargos from './rtkSlices/cargos.js';
import historyEntries from './rtkSlices/historyEntries.js';
import repack from './rtkSlices/repack.js';
import repairTasks from './rtkSlices/repairTasks.js';
import tariffs from './rtkSlices/tariffs.js';
import clients from './rtkSlices/clients.js';
import transporters from './rtkSlices/transporters.js';
import vehicles from './rtkSlices/vehicles.js';
import empls from './rtkSlices/empls.js';
import createJob from './rtkSlices/createJob.js';
import tableLogs from './rtkSlices/tableLogs.js';
import terminals from './rtkSlices/terminals.js';
import legalEntities from './rtkSlices/legalEntities.js'
import extraservices from './rtkSlices/extraservices.js';
import containerRegister from './rtkSlices/containerRegister.js'
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore(
	{
		middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
		reducer: {
			[api.reducerPath]: api.reducer,
			publicForm: publicFormReducer,
			containerAccept: containerAcceptanceReducer, 
			login: loginReducer,
			sideBar: sideBarReducer, 
			me: meReducer, 
			//! RTK Slices
			containersRtk: containers,
			entriesRtk: entries,
			ordersRtk: orders,
			entryreportsRtk: entryreports,
			storageRtk: storage,
			repairsRtk: repairs,
			cargosRtk: cargos,
			historyEntriesRtk: historyEntries,
			repackRtk: repack,
			repairTasksRtk: repairTasks,
			tariffsRtk: tariffs,
			terminalsRtk: terminals,
			clientsRtk: clients,
			transportersRtk: transporters,
			vehiclesRtk: vehicles,
			emplsRtk: empls,
			createJobRtk: createJob,
			tableLogsRtk: tableLogs,
			legalEntitiesRtk: legalEntities,
			extraservicesRtk: extraservices,
			containerRegisterRtk: containerRegister,
		},
	},
);

export default store;
