import React, { useEffect, useState } from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DateRangeInput from '../../../../../newComponents/Inputs/DateRangeInput/DateRangeInput';

const InputRangeDateGroup = ({ name, title, state, onChange, defaultOpened = false, limitMaxDate = false }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleOpen = () => {
		setIsOpen(!isOpen);
	};

	const [startDate, setStartDate] = useState(state[0] ? state[0] : '');
	const [endDate, setEndDate] = useState(state[1] ? state[1] : '');

	useEffect(() => {
		if (state[0] === '' && state[1] === '') {
			setStartDate('');
			setEndDate('');
		}
	}, [state]);

	useEffect(() => {
		if (startDate) {
			onChange(name[0], new Date(startDate).toLocaleDateString('en-CA').split('T')[0]);
		}
		if (endDate) {
			onChange(name[1], new Date(endDate).toLocaleDateString('en-CA').split('T')[0]);
		}
	}, [startDate, endDate]);

	useEffect(() => {
		setIsOpen(defaultOpened);
	}, [defaultOpened]);

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', '&:hover': { cursor: 'pointer' } }} onClick={toggleOpen}>
				<Typography variant="alertTitle" style={{ flexGrow: 1 }}>
					{title}
				</Typography>
				<IconButton>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
			</Box>
			{isOpen && <DateRangeInput limitMaxDate={limitMaxDate} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />}
		</>
	);
};

export default InputRangeDateGroup;
