import { Controller, useFormContext } from 'react-hook-form';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import { Typography } from '@mui/material';
import { ClientInputAutocomplete } from './ClientInputAutocomplete';

export const ClientInputAutocompleteController = () => {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	return (
		<CollapseBlock defaultOpen={true || !!errors?.client} title={<span>Клиент *</span>}>
			<Controller
				name="client"
				control={control}
				render={({ field }) => (
					<>
						<ClientInputAutocomplete {...field} />
						{errors?.client && (
							<Typography color="error" variant="caption">
								{errors?.client?.message}
							</Typography>
						)}
					</>
				)}
			/>
		</CollapseBlock>
	);
};
