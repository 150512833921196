import { isEmpl } from 'shared/auth/isEmpl';
import { LINK_CLIENT_BY_ID } from '../../../../clients/pageUrls';
import { useMemo } from 'react';
import { TERMINAL_PAGE_URL } from 'shared/terminals/pageUrls';
import { LEGAL_PAGE_BY_ID } from 'shared/legalEntities/pageUrls';
import { Button } from '@mui/material';
import { IconDownload } from 'components/Icons';
import { formatDate, formatInitialDate } from 'helpers/formatDate';

export const useGetSummaryTableColumns = () => {
	const columnsList = useMemo(() => {
		const baseColumns = [
			{
				accessorFn: (row) => row.service_display,
				id: 'service',
				header: 'Услуга',
				size: 230,
			},

			{
				accessorFn: (row) => (row.created_at ? formatInitialDate(row.created_at) : '-'),
				id: 'created_at',
				header: 'Дата создания',
				size: 230,
			},

			{
				accessorFn: (row) => row.manager_display,
				id: 'manager',
				header: 'Менеджер',
				size: 230,
			},

			{
				accessorFn: (row) => row.status_display,
				id: 'status',
				header: 'Статус',
				size: 230,
			},

			{
				accessorFn: (row) => (row.date_from ? formatDate(row.date_from) : '-'),
				id: 'date_from',
				header: 'Начало расчета',
				size: 250,
			},

			{
				accessorFn: (row) => (row.date_to ? formatDate(row.date_to) : '-'),
				id: 'date_to',
				header: 'Окончание расчета',
				size: 250,
			},

			{
				accessorFn: (row) => {
					return (
						<a href={row.url} target="_blank" style={{ textDecoration: 'none' }}>
							<Button
								disabled={!row.url}
								size="small"
								variant="contained"
								color="primary"
								sx={{ display: 'flex', gap: '8px', margin: '0 auto' }}
							>
								Скачать
								{/* @ts-ignore */}
								<IconDownload color={'white'} />
							</Button>
						</a>
					);
				},
				id: 'download',
				header: 'Скачать',
				size: 180,
			},
		];

		if (isEmpl()) {
			baseColumns.push(
				{
					accessorFn: (row) => {
						const url = TERMINAL_PAGE_URL(row.terminal);
						return (
							<a href={url} target="_blank" rel="noopener noreferrer nofollow">
								{row.terminal_display}
							</a>
						);
					},
					id: 'terminal',
					header: 'Терминал',
					size: 230,
				},

				{
					accessorFn: (row) => {
						const url = LEGAL_PAGE_BY_ID(row.legal_entity);
						return (
							<a href={url} target="_blank" rel="noopener noreferrer nofollow">
								{row.legal_entity_display}
							</a>
						);
					},
					id: 'legal_entity',
					header: 'Юр. лицо',
					size: 230,
				},
				{
					accessorFn: (row) => {
						const url = LINK_CLIENT_BY_ID(row.client);
						return (
							<a href={url} target="_blank" rel="noopener noreferrer nofollow">
								{row.client_display}
							</a>
						);
					},
					id: 'client',
					header: 'Клиент',
					size: 280,
				},
			);
		} else {
			baseColumns.push(
				{
					accessorFn: (row) => row.terminal_display,
					id: 'terminal',
					header: 'Терминал',
					size: 230,
				},

				{
					accessorFn: (row) => row.legal_entity_display,
					id: 'legal_entity',
					header: 'Юр. лицо',
					size: 230,
				},
			);
		}

		return baseColumns;
	}, [isEmpl()]);

	const columnOrdering = [
		'mrt-row-select',
		'download',
		'terminal',
		'legal_entity',
		'client',
		'service',
		'date_from',
		'date_to',
		'created_at',
		'manager',
		'status',
	];

	return { columnsList, columnOrdering };
};
