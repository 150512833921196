import { InputAutocompleteAsync } from '../../Inputs/InputAutocomplete/InputAutocompleteAsync';
import { useCallback } from 'react';
import { Box } from '@mui/material';
import { useLazyGetEmplsListQuery } from '../../../store/newApi/users/users';

type ManagersInputAutocompleteProps = {
	value: number | string;
	onChange: (value: number | string) => void;
	rest?: any,
};

export const ManagersInputAutocomplete = ({ value, onChange, ...rest }: ManagersInputAutocompleteProps) => {
	// @ts-ignore
	const [trigger, { staffOptions = [], loading: loadingStaff }] = useLazyGetEmplsListQuery({
		selectFromResult: ({ data }) => ({
			staffOptions: data?.results?.map((item) => ({ label: item.full_name, id: item.id })),
		}),
	});

	const handleSearch = useCallback(
		(value) => {
			trigger({
				search: value,
				user_group: 3,
			});
		},
		[trigger],
	);

	return (
		<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
			<InputAutocompleteAsync
				{...rest}
				value={value}
				fetchFn={handleSearch}
				placeholder="Выберите или введите вручную"
				options={staffOptions}
				loading={loadingStaff}
				onChange={onChange}
			/>
		</Box>
	);
};
