import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';
import { LINK_ENTRY_BY_ID, PAGE_ENTRIES } from 'shared/entries/pageUrls';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { useCreateBatchEntryMutation, useCreateEntryMutation } from 'store/newApi/entries/entries';

export const useHandleCreate = ({ refetchData, quantityEntries, dataOrder, ...methods }) => {
	const { showNotificaton } = useNotify();
	let { orderId } = useParams();
	const navigate = useNavigate();
	const { reset: resetForm } = methods;
	const [createEntry, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateEntryMutation }] =
		useCreateEntryMutation();
	const [creatBatchEntry, { isSuccess: isCreateBatchSuccess, isLoading: isCreateBatchLoading, reset: resetCreateBatchEntryMutation }] =
		useCreateBatchEntryMutation();

	const handleCreate = useCallback(
		(value) => {
			if (quantityEntries > 0) {
				if (orderId) {
					creatBatchEntry({ ...value, order: +orderId, type: 2, count: quantityEntries }).then((data) => {
						if (data.error) {
							showNotificaton({
								type: 'error',
								message: tranformErrorsToRender(data.error?.data, 'Не удалось создать пропуск(а)'),
							});
						} else {
							navigate(PAGE_ENTRIES());
							resetCreateBatchEntryMutation();
							resetForm();
							showNotificaton({
								type: 'success',
								message: 'Пропуск(а) успешно создан(ы)',
							});
						}
					});
				} else {
					creatBatchEntry({ ...value, type: dataOrder?.results[0]?.type, count: quantityEntries }).then((data) => {
						if (data.error) {
							showNotificaton({
								type: 'error',
								message: tranformErrorsToRender(data.error?.data, 'Не удалось создать пропуск(а)'),
							});
						} else {
							navigate(PAGE_ENTRIES());
							resetCreateBatchEntryMutation();
							resetForm();
							showNotificaton({
								type: 'success',
								message: 'Пропуск(а) успешно создан(ы)',
							});
						}
					});
				}
			} else {
				createEntry({
					...value,
				}).then((data) => {
					if (data.error) {
						showNotificaton({
							type: 'error',
							message: tranformErrorsToRender(data.error?.data, 'Не удалось создать пропуск'),
						});
					} else {
						navigate(LINK_ENTRY_BY_ID(data?.data.id));
						resetCreateEntryMutation();
						resetForm();
						showNotificaton({
							type: 'success',
							message: 'Пропуск успешно создан',
						});
					}
				});
			}
		},
		[createEntry, quantityEntries, orderId, creatBatchEntry, dataOrder],
	);

	return {
		handleCreate,
		isCreateSuccess,
		isCreateLoading,
		isCreateBatchSuccess,
		isCreateBatchLoading,
	};
};
