import { useCallback } from 'react';
import { useLazyGetClientsListQuery } from '../../../store/newApi/organizations/organizations';
import { Box } from '@mui/material';
import MultiSelectAutocomplete from 'newComponents/Inputs/InputAutocomplete/MultiInputAutocomplete';

export const ClientInputMultiAutocomplete = ({ selectedValues = [], onChange, ...rest }) => {
	// @ts-ignore
	const [trigger, { clientsOptions = [], loading: loadingClients }] = useLazyGetClientsListQuery({
		selectFromResult: ({ data }) => ({
			clientsOptions: data?.results?.map((t) => ({ label: t.organization_name, id: t.id })),
		}),
	});
	const handleSearchClients = useCallback(
		(value: string) => {
			trigger({ search: value, page_size: 100 });
		},
		[trigger],
	);

	return (
		<Box sx={{ width: '100%', display: 'flex', gap: '8px' }}>
			<MultiSelectAutocomplete
				fetchOptions={handleSearchClients}
				options={clientsOptions}
				value={selectedValues}
				onChange={onChange}
				label="Выберите пользователей"
				placeholder="Начните вводить имя"
			/>
		</Box>
	);
};
