import { Radio, RadioGroup, FormControlLabel, Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CONTAINER_SIZE, CONTAINER_TYPE } from '../../../shared/containers/constants';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import InputText from '../../Inputs/InputText/InputText';
import { Switcher } from '../../../components';
import { DrawerModal } from '../../DrawerModal/DrawerModal';
import { FormWrapper } from '../FormWrapper';
import { LineInputAutocompleteController } from '../Fields/LineInputAutocompleteController';
import { TerminalInputAutocompleteController } from '../Fields/TerminalInputAutocompleteController';
import { useHandleCreate } from './hooks/useHandleCreate';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { ClientInputAutocompleteController } from '../Fields/ClientInputAutocompleteController';
import { useInitFormState } from './hooks/useInitFormState';
import { isClient } from '../../../shared/auth/isClient';
import { ImportContainers } from '../../../shared/ui/ImportContainers/ImportContainers';
import { useGetFormSchema } from './hooks/useGetFormSchema';
import { IsoCodeInputAutoComplete } from '../Fields/IsoCodeInputAutoComplete/IsoCodeInputAutoComplete';
import { useLazyGetIsoCodesListQuery } from 'store/newApi/isoCodes/isoCodes';
import { TypeInputAutocomplete } from '../Fields/TypeInputAutocomplete';
import { SizeInputAutocomplete } from '../Fields/SizeInputAutocomplete';

export const ContainerForm = ({
	isOpen,
	isImport = false,
	handleClose,
	refetchData,
	entityIds = [],
	notEditableValues = {},
	createdCallback = (containerId) => {},
}) => {
	const [isImportOpen, setIsImportOpen] = useState(false);

	const isSingleUpdate = useMemo(() => entityIds.length === 1, [entityIds]);
	const isBulkUpdate = useMemo(() => entityIds.length > 1, [entityIds]);
	const isCreate = useMemo(() => entityIds.length === 0 && !isImport, [entityIds, isImport]);

	const { resolver } = useGetFormSchema({ isCreate, isImport });

	const methods = useForm({
		defaultValues: {
			line: undefined,
			containerNumber: '',
			size: undefined,
			type: undefined,
			client: undefined,
			bookingNumber: '',
			isFilled: false,
			isDangerous: false,
			isDamaged: false,
			isBlocked: false,
			terminal: undefined,
			isoCode: undefined,
			location: '',
			stock: '',
		},
		resolver: resolver,
	});
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors },
	} = methods;

	useEffect(() => {
		if (notEditableValues['client']) setValue('client', +notEditableValues['client'], { shouldTouch: true });
		if (notEditableValues['terminal']) setValue('terminal', +notEditableValues['terminal'], { shouldTouch: true });
	}, [notEditableValues, setValue]);

	const { handleCreate, isCreateLoading } = useHandleCreate({ ...methods, handleClose, refetchData, createdCallback });
	const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods, entityIds, handleClose, refetchData });
	useInitFormState({ ...methods, ids: entityIds, isReady: isOpen });

	const isFilled = watch('isFilled');
	const isDangerous = watch('isDangerous');
	const isDamaged = watch('isDamaged');
	const isBlocked = watch('isBlocked');
	const terminalValue = watch('terminal');
	const clientValue = watch('client');
	const isoCodeValue = watch('isoCode');
	const [fetchIsoCodes, { data: dataIsoCodes }] = useLazyGetIsoCodesListQuery();

	const importDisabled = useMemo(() => !terminalValue && !clientValue, [terminalValue, clientValue]);

	const onSubmit = useCallback(
		(value) => {
			if (isCreate) handleCreate(value);
			else handleUpdate(value);
		},
		[handleCreate, handleUpdate, isCreate],
	);

	const handleOpenImportPopup = useCallback(() => {
		if (importDisabled) handleSubmit(() => {})();
		else setIsImportOpen(true);
	}, [importDisabled, handleSubmit]);

	const renderActionBtn = useMemo(() => {
		if (isImport)
			return (
				<Button onClick={handleOpenImportPopup} variant="contained" color="primary">
					Импортировать
				</Button>
			);
		return (
			<Button isLoading={isCreateLoading || isUpdateLoading} onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
				Сохранить
			</Button>
		);
	}, [isImport, isCreateLoading, isUpdateLoading, handleSubmit, onSubmit, handleOpenImportPopup]);

	useEffect(() => {
		if (isoCodeValue) {
			fetchIsoCodes({ iso_id: isoCodeValue });
		}
	}, [isoCodeValue]);

	useEffect(() => {
		if (dataIsoCodes && isoCodeValue) {
			setValue('size', `${dataIsoCodes.results[0]?.size}`)
			setValue('type', `${dataIsoCodes.results[0]?.type}`)
		}
	}, [dataIsoCodes, isoCodeValue]);

	const handleResetIsoCode = () => {
		setValue('isoCode', '')
	}

	return (
		<>
			<DrawerModal isOpen={isOpen} handleClose={handleClose} actions={renderActionBtn}>
				<Typography variant="subtitle1">{isCreate ? 'Добавление' : 'Редактирование'}</Typography>
				<FormWrapper onSubmit={onSubmit} {...methods}>
					{!notEditableValues['terminal'] && <TerminalInputAutocompleteController />}
					{!isClient() && !notEditableValues['client'] && <ClientInputAutocompleteController />}
					{!isImport && (
						<>
							{!isBulkUpdate && (
								<CollapseBlock defaultOpen={true || !!errors.containerNumber} title={<span>№ Контейнера *</span>}>
									<Controller
										name="containerNumber"
										control={control}
										render={({ field }) => (
											<>
												<InputText {...field} placeholder="Введите номер" />
												{errors?.containerNumber && (
													<Typography color="error" variant="caption">
														{errors?.containerNumber?.message}
													</Typography>
												)}
											</>
										)}
									/>
								</CollapseBlock>
							)}
							<LineInputAutocompleteController />
							<CollapseBlock title="Код">
								<Controller
									name="isoCode"
									control={control}
									render={({ field }) => (
										<>
											<IsoCodeInputAutoComplete {...field} />
											{errors?.isoCode && (
												<Typography color="error" variant="caption">
													{errors?.isoCode?.message}
												</Typography>
											)}
										</>
									)}
								/>
							</CollapseBlock>
							<CollapseBlock title="Размер *" defaultOpen>
								<Controller
									name="size"
									control={control}
									render={({ field }) => (
										<>
											<SizeInputAutocomplete {...field} resetIsoCode={handleResetIsoCode}/>
											{errors?.size && (
												<Typography color="error" variant="caption">
													{errors?.size?.message}
												</Typography>
											)}
										</>
									)}
								/>
							</CollapseBlock>
							<CollapseBlock title="Тип *" defaultOpen>
								<Controller
									name="type"
									control={control}
									render={({ field }) => (
										<>
											<TypeInputAutocomplete {...field} resetIsoCode={handleResetIsoCode}/>
											{errors?.type && (
												<Typography color="error" variant="caption">
													{errors?.type?.message}
												</Typography>
											)}
										</>
									)}
								/>
							</CollapseBlock>
							<CollapseBlock title="Букинг">
								<Controller
									name="bookingNumber"
									control={control}
									render={({ field }) => <InputText {...field} placeholder="Введите номер" />}
								/>
							</CollapseBlock>
							<CollapseBlock title="Сток">
								<Controller
									name="stock"
									control={control}
									render={({ field }) => <InputText {...field} disabled={isClient()} placeholder="Введите текст" />}
								/>
							</CollapseBlock>
							<CollapseBlock title="Место">
								<Controller
									name="location"
									control={control}
									render={({ field }) => <InputText {...field} placeholder="Введите текст" />}
								/>
							</CollapseBlock>
							<CollapseBlock title="Заблокирован">
								<Switcher
									onChecked={(checked) => setValue('isBlocked', checked, { shouldTouch: true })}
									externalChecked={isBlocked}
									right="Да"
								/>
							</CollapseBlock>
							<CollapseBlock title="Заполнен">
								<Switcher
									onChecked={(checked) => setValue('isFilled', checked, { shouldTouch: true })}
									externalChecked={isFilled}
									right="Да"
								/>
							</CollapseBlock>
							<CollapseBlock title="Поврежден">
								<Switcher
									onChecked={(checked) => setValue('isDamaged', checked, { shouldTouch: true })}
									externalChecked={isDamaged}
									right="Да"
								/>
							</CollapseBlock>
							<CollapseBlock title="Опасный груз">
								<Switcher
									onChecked={(checked) => setValue('isDangerous', checked, { shouldTouch: true })}
									externalChecked={isDangerous}
									right="Да"
								/>
							</CollapseBlock>
						</>
					)}
				</FormWrapper>
			</DrawerModal>
			<ImportContainers
				isOpen={isImportOpen}
				toggleIsOpen={(flag) => {
					setIsImportOpen(flag);
					handleClose();
				}}
				defaultCreateFields={{
					terminalId: terminalValue,
					orgId: clientValue,
				}}
				importedCallback={(containerIds) => refetchData()}
			/>
		</>
	);
};
