import { useCallback, useEffect } from "react";
import { useCreateRegisterContainerMutation } from "../../../../store/newApi/containerRegister/containerRegister";

export const useHandleCreate = ({ handleClose, refetchData = () => {}, createdCallback = (id: number) => {}, ...methods }) => {
    const { reset: resetForm } = methods;
    const [createContainer, { data: createdContainer, isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateContainerMutation }] = useCreateRegisterContainerMutation();

    useEffect(() => {
        if (createdContainer?.id)
            createdCallback(createdContainer?.id)
    }, [createdCallback, createdContainer]);

    useEffect(() => {
        if (isCreateSuccess) {
            handleClose();
            resetCreateContainerMutation();
            resetForm();
            refetchData();
            // TODO: Вызвать нотификашку
        }

        // return () => {
        //     resetForm();
        // }
    }, [isCreateSuccess, handleClose, resetCreateContainerMutation, refetchData, resetForm]);

    const handleCreate = useCallback((value) => {
        createContainer({
            terminalId: value.terminal,
            clientIds: value?.clients?.map(c => c.id) || [],
            serviceIds: [value.service],
            dateFrom: value.dateFrom,
            dateTo: value.dateTo
        });
    }, [createContainer]);

    return {
        handleCreate,
        isCreateSuccess,
        isCreateLoading
    }
}