import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { FormWrapper } from '../FormWrapper';
import InputText from '../../Inputs/InputText/InputText';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useInitFormState } from './hooks/useInitFormState';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { useHandleCreate } from './hooks/useHandleCreate';
import { entriesForm } from './EntriesForm.style';
import { useNavigate, useParams } from 'react-router-dom';
import { ContainerInputAutocomplete } from '../Fields/ContainerInputAutocomplete';
import { VehicleInputAutocomplete } from '../Fields/VenicleInputAutoComplete/VehicleInputAutocomplete';
import { PAGE_ENTRIES } from '../../../shared/entries/pageUrls';
import { AssigneesInputAutocomplete } from '../Fields/AssigneesInputAutocomplete';
import { filterAssignees, handleFindWorker } from '../../../shared/empls/utils';
import { OrdersInputAutocomplete } from '../Fields/OrdersInputAutocomplete'; 
import { useLazyGetOrdersListQuery } from 'store/newApi/orders/orders';
import { useLazyGetContainerByIdQuery } from 'store/newApi/containers/containers';

const createSchema = z.object({
	container: z.any().optional(),
	vehicle_driver: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	vehicle: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	vehicle_trailer_number: z.any().optional(),
	seal_number: z.any().optional(), 
	assignee: z.any().optional(),
	order: z.any().optional(), 
});

const updateSchema = z.object({
	container: z.any().optional(),
	vehicle_driver: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	vehicle: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	vehicle_trailer_number: z.any().optional(),
	seal_number: z.any().optional(),
	assignee: z.any().optional(),
	order: z.any().optional(), 
});

export const EntriesForm = ({ data, refetchData, isCreate = false, setSelectContainer, setSelectOrder, quantityEntries }) => {
	const resolver = useMemo(() => (isCreate ? zodResolver(createSchema) : zodResolver(updateSchema)), [isCreate]);
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
	const navigate = useNavigate();
	let { orderId } = useParams();
	const [triggerOrder, { data: dataOrder }] = useLazyGetOrdersListQuery();
	const [triggerContainer, { data: dataContainer }] = useLazyGetContainerByIdQuery();

	const methods = useForm({
		defaultValues: {
			container: undefined,
			vehicle_driver: undefined,
			vehicle: undefined,
			vehicle_trailer_number: undefined,
			seal_number: undefined,
			assignee: undefined, 
			order: undefined,
		},
		resolver: resolver,
	});

	const { handleUpdate } = useHandleUpdate({ ...methods, refetchData, quantityEntries });
	const { handleCreate } = useHandleCreate({ ...methods, refetchData, dataOrder, quantityEntries });

	const {
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = methods;

	const onSubmit = useCallback(
		(value) => {
			if (isCreate) {
				handleCreate(value);
			} else {
				handleUpdate(value);
			}
		},
		[handleCreate, handleUpdate, isCreate],
	);

	useInitFormState({ data, isCreate, ...methods });

	const isContainer = watch('container');

	useEffect(() => {
		if (isContainer && Number.isFinite(isContainer)) {
			triggerContainer({ id: isContainer });
			setSelectContainer(true);
		} else {
			setSelectContainer(false);
		}
	}, [isContainer]);

	const isOrder = watch('order');

	useEffect(() => {
		if (isOrder) {
			triggerOrder({ search: isOrder });
			setSelectOrder(true);
		} else {
			setSelectOrder(false);
		}
	}, [isOrder]);

	return (
		<FormWrapper onSubmit={onSubmit} {...methods}>
			<Grid
				container
				sx={{
					background: '#fff',
					padding: '12px',
					borderRadius: '12px',
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					gap: '8px',
				}}
			>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Controller
						name="container"
						control={control}
						render={({ field }) =>
							data?.container ? (
								<InputText {...field} disabled placeholder="Номер контейнера" labelText="Номер контейнера" />
							) : (
								<ContainerInputAutocomplete {...field} labelText="Номер контейнера" />
							)
						}
					/>
				</Grid>

				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Controller
						name="order"
						control={control}
						render={({ field }) => (
							<OrdersInputAutocomplete
								{...field}
								value={orderId ? Number(orderId) : field.value}
								disabled={!isCreate}
								placeholder="Номер заявки"
								labelText="Номер заявки"
							/>
						)}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Controller
						name="vehicle"
						control={control}
						render={({ field }) => (
							<>
								<VehicleInputAutocomplete
									{...field}
									value={field.value}
									disabled={!isCreate && data?.status !== 0}
									labelText="Номер автомобиля"
									onChange={(newVehicle) => field.onChange(newVehicle?.id)}
								/>
								{errors?.vehicle && (
									<Typography color="error" variant="caption">
										{errors?.vehicle?.message}
									</Typography>
								)}
							</>
						)}
					/>
				</Grid> 
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Controller
						name="vehicle_driver"
						control={control}
						render={({ field }) => (
							<InputText
								{...field}
								disabled={!isCreate && data?.status !== 0}
								errorMessage={errors?.vehicle_driver?.message}
								placeholder="Водитель"
								labelText="Водитель"
							/>
						)}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Controller
						name="vehicle_trailer_number"
						control={control}
						render={({ field }) => (
							<InputText
								{...field}
								disabled={!isCreate && data?.status !== 0}
								errorMessage={errors?.vehicle_trailer_number?.message}
								placeholder="Номер прицепа"
								labelText="Номер прицепа"
							/>
						)}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Controller
						name="seal_number"
						control={control}
						render={({ field }) => (
							<InputText
								{...field}
								disabled={!isCreate && data?.status !== 0}
								errorMessage={errors?.seal_number?.message}
								placeholder="Номер пломбы"
								labelText="Номер пломбы"
							/>
						)}
					/>
				</Grid> 
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Controller
						name="assignee"
						control={control}
						render={({ field }) => (
							<AssigneesInputAutocomplete
								{...field}
								disabled={!isCreate && data?.status !== 0}
								group={3}
								valueName={
									data?.assignees ? handleFindWorker({ assignees: data?.assignees, type: 0, item: 'assignee_name' }) : ''
								}
								assigneesHistory={data?.assignees ? filterAssignees({ assignees: data?.assignees, type: 0 }) : []}
								terminal={data ? data?.terminal?.id : dataContainer ? dataContainer?.terminal : null}
								labelText="Ответственный менеджер"
							/>
						)}
					/>
				</Grid>
			</Grid>
			<Box sx={entriesForm(isDesktop)}>
				<Button variant="outlined" color="primary" onClick={() => navigate(PAGE_ENTRIES())}>
					Назад
				</Button>
				{data?.status == 0 && (
					<Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
						Сохранить
					</Button>
				)}
				{isCreate && (
					<Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
						Создать
					</Button>
				)}
			</Box>
		</FormWrapper>
	);
};
