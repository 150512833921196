import { InputAutocompleteAsync } from '../../Inputs/InputAutocomplete/InputAutocompleteAsync';
import { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { useLazyGetOrdersListQuery } from 'store/newApi/orders/orders';

export const OrdersInputAutocomplete = ({ value, ...rest }) => {
	const [trigger, { options = [], loading }] = useLazyGetOrdersListQuery({
		selectFromResult: ({ data }) => ({
			options: data?.results?.map((t) => ({ label: String(t.id), id: t.id })),
		}),
	});

	const handleSearch = useCallback(
		(value) => {
			trigger({ search: value });
		},
		[value, trigger],
	);

	useEffect(() => {
		if (value) {
			trigger({ search: value });
		}
	}, [value, trigger]);

	return (
		<Box sx={{ width: '100%', display: 'flex', gap: '8px' }}>
			<InputAutocompleteAsync
				{...rest}
				value={value}
				fetchFn={handleSearch}
				placeholder="Выберите или введите вручную"
				options={options}
				loading={loading}
			/>
		</Box>
	);
};
