import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useEffect, useMemo, useState } from 'react';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { useLazyGetEntriesListCompleteQuery } from '../../../../store/newApi/entries/entries';
import { formatInitialDate } from '../../../../helpers/formatDate';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/historyEntries.js';
import { useDispatch } from 'react-redux';
import { FILTER_TYPES } from '../../../../config/filter';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { Filters } from '../../../../shared/ui/Filters/Filters';
import { LINK_CONTAINER_BY_ID } from 'shared/containers/pageUrls';

function AccountEmplTableHistoryVehicles() {
	const dispatch = useDispatch();
	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});
	const [fetchEntries, { data, isError, isLoading }] = useLazyGetEntriesListCompleteQuery();

	// колонки
	const columnsList = useMemo(
		() => [
			{
				accessorFn: (row) => row.name,
				id: 'name',
				header: 'Пропуск',
				size: 180,
			},
			{
				accessorFn: (row) => {
					const url = LINK_CONTAINER_BY_ID(row.container?.id);
					return (
						<a href={url} target="_blank" rel="noopener noreferrer nofollow">
							{row.container?.container_number}
						</a>
					);
				},
				id: 'container_number',
				header: '№ контейнера',
				size: 230,
			},
			{
				accessorFn: (row) => row.terminal?.name,
				id: 'terminal_name',
				header: 'Терминал',
				size: 180,
			},

			{
				accessorFn: (row) => row.vehicle_display,
				id: 'vehicle_display',
				header: 'Номер автомобиля',
				size: 250,
			},

			{
				accessorFn: (row) => row.vehicle_driver,
				id: 'vehicle_driver',
				header: 'Водитель',
				size: 200,
			},
			{
				accessorFn: (row) => (row.chekin_at ? formatInitialDate(row.chekin_at) : ''),
				id: 'chekin_at',
				header: 'Время приезда',
				size: 220,
			},
			{
				accessorFn: (row) => row.seal_number,
				id: 'seal_number',
				header: 'Номер пломбы',
				size: 220,
			},
		],
		[],
	);

	// порядок колонок
	const columnOrdering = [
		'mrt-row-select',
		'name',
		'container_number',
		'terminal_name',
		'vehicle_display',
		'vehicle_driver',
		'chekin_at',
		'seal_number',
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'История движений автомобилей по пропускам'}
				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'terminal_id',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							{
								name: ['chekin_at_from', 'chekin_at_to'],
								title: 'Дата приезда',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
						]}
					/>
				}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						fetchData={fetchEntries}
						isLoading={isLoading}
						isError={isError}
						data={data}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						filterName="historyEntriesRtk"
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTableHistoryVehicles;
