import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isClient } from "../isClient";

export const useGetPermissions = () => {
    // @ts-ignore
    const userData = useSelector((state) => state.me.userData);

    const terminalData = useMemo(() => (userData?.terminals || [])[0], [userData])
    const org = useMemo(() => (userData?.organizations || [])[0], [userData])
    const orgId = useMemo(() => (userData?.organizations || [])[0]?.id || null, [userData])

    const isRemontnik = useMemo(() =>
        terminalData?.group === 'Менеджер по ремонту', [terminalData]);

    const clientCanConfirmRepair = org?.can_confirm_repair;

    return {
        isRemontnik,
        clientCanConfirmRepair,
        orgId,
        isClient: isClient,
    };
}