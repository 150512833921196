import { useCallback, useEffect } from "react"; 
import { useCreateTariffMutation } from "../../../../store/newApi/service/service";

export const useHandleCreate = ({ handleClose, refetchData, ...methods }) => {
    const { reset: resetForm } = methods;
    const [createTariff, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateTariffMutation }] = useCreateTariffMutation();

    useEffect(() => {
        if (isCreateSuccess) {
            handleClose();
            resetCreateTariffMutation();
            resetForm();
            refetchData();
            // TODO: Вызвать нотификашку
        }

        return () => {
            resetForm();
        }
    }, [isCreateSuccess, handleClose, resetCreateTariffMutation, resetForm, refetchData]);

    const handleCreate = useCallback((value) => {
        createTariff({ 
            terminal: value.terminal, 
            name: value.name,
            // description: value.description,
            // price: parseFloat(value.price),
            unit: value.unit, 
            price_20: value.price_20, 
            price_40: value.price_40, 
            price_45: value.price_45,
        });
    }, [createTariff]);

    return {
        handleCreate,
        isCreateSuccess,
        isCreateLoading
    }
}