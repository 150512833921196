import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../../../newComponents/Header/Header';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { useInitFormState } from './hooks/useInitFormState';
import styles from './AccountEmplContainer.module.css';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { PageFooterActions } from '../../../../../shared/ui/PageFooterActions/PageFooterActions';
import { LINK_CONTAINERS_PAGE } from '../../../../../shared/containers/pageUrls';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { ContainerDetail } from './components/ContainerDetail';
import { ContainerActs } from './components/ContainerActs';
import { useLazyGetContainerByIdQuery } from 'store/newApi/containers/containers';
import { Typography } from '@mui/material';
import InputText from 'newComponents/Inputs/InputText/InputText';

const updateSchema = z.object({
	containerNumber: z.string().optional(),
	terminal: z.number().optional(),
	client: z.number().optional(),
	size: z
		.string()
		.optional()
		.transform((val) => +val),
	type: z
		.string()
		.optional()
		.transform((val) => +val),
	line: z.number().optional(),
	bookingNumber: z.string().optional(),
	isFilled: z.boolean().optional(),
	isDamaged: z.boolean().optional(),
	isDangerous: z.boolean().optional(),
	isoCode: z.any().optional(),
	isBlocked: z.boolean().optional(),
	stock: z.string().optional(),
	location: z.string().optional(),
	note: z.string().optional(),
});

export const AccountEmplContainer = () => {
	const { containerId } = useParams();
	const navigate = useNavigate();
	const [optionsAct, setOptionsAct] = useState([]);
	const [trigger, { data }] = useLazyGetContainerByIdQuery();

	const resolver = zodResolver(updateSchema);
	const methods = useForm({
		defaultValues: {
			line: undefined,
			containerNumber: '',
			size: undefined,
			type: undefined,
			client: undefined,
			bookingNumber: '',
			isFilled: false,
			isDangerous: false,
			isDamaged: false,
			isBlocked: false,
			terminal: undefined,
			isoCode: undefined,
			stock: '',
			location: '',
			note: '',
		},
		resolver: resolver,
	});

	const { handleUpdate } = useHandleUpdate({ ...methods, refetch: trigger, containerId });
	useInitFormState({ ...methods, data, setOptionsAct });

	const {
		// @ts-ignore
		control,
		handleSubmit,
		formState: { errors },
	} = methods;

	const onSubmit = useCallback(
		(value) => {
			handleUpdate(value);
		},
		[handleUpdate],
	);

	const handleBack = () => {
		navigate(LINK_CONTAINERS_PAGE());
	};

	useEffect(() => {
		if (containerId) {
			trigger({ id: containerId });
		}
	}, [containerId]);

	return (
		<div style={{ width: '100%' }}>
			{/* @ts-ignore */}
			<Header
				text={`Контейнер №${data?.container_number}`}
				btnDesktop={[`${data?.status_display}`]}
				btnMobile={[`${data?.status_display}`]}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<div className={styles.block}>
						<ContainerDetail {...methods} onSubmit={onSubmit} />
					</div>
					<div className={styles.block}>
						<ContainerActs options={optionsAct} />
					</div>
					<div className={styles.block}>
						<Controller
							name="note"
							control={control}
							render={({ field }) => (
								<>
									{/* @ts-ignore  */}
									<InputText {...field} height="200px" placeholder="Введите текст" labelText="Примечание" />
									{errors?.note && (
										<Typography color="error" variant="caption">
											{/* @ts-ignore */}
											{errors?.note?.message}
										</Typography>
									)}
								</>
							)}
						/>
					</div>
				</div>
				<PageFooterActions
					leftBtns={[
						{
							text: 'Назад',
							variant: 'outlined',
							color: 'primary',
							size: 'medium',
							onClick: handleBack,
						},
					]}
					rightBtns={[
						{
							text: 'Сохранить',
							variant: 'contained',
							color: 'primary',
							size: 'medium',
							onClick: handleSubmit(onSubmit),
						},
					]}
				/>
			</div>
		</div>
	);
};
