import { useCallback } from 'react';
import { filterTouchedFields } from '../../utils/filterTouchedFields';
import { useUpdateEntryMutation } from '../../../../store/newApi/entries/entries';
import { useParams } from 'react-router-dom';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { tranformErrorsToRender } from 'shared/api/tranformErrorsToRender';

export const useHandleUpdate = ({ refetchData, ...methods }) => {
	const { showNotificaton } = useNotify();
	let { entryId } = useParams();
	const {
		formState: { touchedFields },
	} = methods;
	const [update, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdate }] = useUpdateEntryMutation();

	const handleUpdate = useCallback(
		(value) => {
			const touchedFieldValues = filterTouchedFields(value, touchedFields);
			const payload = {
				vehicle: value?.vehicle == undefined ? null : value?.vehicle,
				container_number: touchedFieldValues?.container_number,
				vehicle_driver: touchedFieldValues?.vehicle_driver === '' ? null : touchedFieldValues?.vehicle_driver,
				vehicle_trailer_number:
					touchedFieldValues?.vehicle_trailer_number === '' ? null : touchedFieldValues?.vehicle_trailer_number,
				seal_number: touchedFieldValues?.seal_number === '' ? null : touchedFieldValues?.seal_number, 
				order: touchedFieldValues?.order,
			};

			if (touchedFieldValues.assignee) {
				payload['set_assignee'] = {
					worker: touchedFieldValues.assignee,
					type: 0,
				};
			}

			update({
				pass_pk: entryId,
				...payload,
			}).then((data) => {
				if (data.error) {
					showNotificaton({
						type: 'error',
						message: tranformErrorsToRender(data.error?.data, 'Не удалось обновить пропуск'),
					});
				} else {
					resetUpdate();
					refetchData();
					showNotificaton({
						type: 'success',
						message: 'Пропуск успешно обновлен',
					});
				}
			});
		},
		[update, touchedFields],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
