import { useCallback, useEffect, useState } from 'react';
import { InputAutocomplete } from './InputAutocomplete';

export const InputAutocompleteAsync = ({ fetchFn, value, placeholder, onChange, options, loading, ...rest }) => {
	const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        const handler = setTimeout(() => {
            fetchFn(inputValue);
        }, 600);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue, fetchFn]);

    const handleChange = useCallback((event, value) => {
        console.log(value)
        setInputValue(value);
    }, [])

    return (
        <InputAutocomplete
            {...rest}
            value={value}
            options={options}
            onChange={onChange}
            placeholder={placeholder}
            onInputChange={handleChange}
            loading={loading}
        />
    )
}