export const account = {
	container: {
		padding: '8px',
		maxHeight: '100%',
		overflowY: 'auto',
	},

	preloader: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

	btns: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},

	gridContainer: { paddingTop: '8px', maxHeight: '100%', display: 'flex', flexWrap: 'wrap', gap: '8px' },
};

export const accountGridItemHalf = (isDesktop) => {
	return [
		{
			width: isDesktop ? '49%' : '100%',

			a: {
				height: '100%',
			},
		},
	];
};

export const accountGridItemThird = (isDesktop, isTablet) => {
	return [
		{
			width: isDesktop ? '32.7%' : isTablet ? '49%' : '100%', 

			a: {
				height: '100%',
			},
		},
	];
};
