import { useCallback, useEffect, useMemo } from "react";
import { useUpdateRegisterContainerMutation } from "../../../../store/newApi/containerRegister/containerRegister";

export const useHandleUpdate = ({ entityIds, handleClose, refetchData = () => {}, ...methods }) => {
    const isSingleUpdate = useMemo(() => entityIds.length === 1, [entityIds]);
    const { formState: { touchedFields }, reset: resetForm } = methods;
    const [updateContainer, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdateContainerMutation }] = useUpdateRegisterContainerMutation();

    useEffect(() => {
        if (isUpdateSuccess) {
            handleClose();
            resetUpdateContainerMutation();
            resetForm();
            refetchData();
            // TODO: Вызвать нотификашку
        }

        // return () => {
        //     resetForm();
        // }
    }, [isUpdateSuccess, handleClose, resetUpdateContainerMutation, resetForm, refetchData]);

    const handleUpdate = useCallback((value) => {
        const payload = {
            containerRegisters: entityIds,
            status: value.status
        }
        updateContainer(payload)
    }, [entityIds, updateContainer, touchedFields, isSingleUpdate]);

    return {
        handleUpdate,
        isUpdateSuccess,
        isUpdateLoading
    }
}