import { Button, Typography } from "@mui/material"
import { DrawerModal } from "../../DrawerModal/DrawerModal"
import { Controller, useForm } from "react-hook-form";
import { useCallback, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from 'zod';
import { useHandleCreate } from "./hooks/useHandleCreate";
import { useHandleUpdate } from "./hooks/useHandleUpdate";
import { FormWrapper } from "../FormWrapper";
import { ClientInputAutocompleteController } from "../Fields/ClientInputAutocompleteController";
import { TerminalInputAutocompleteController } from "../Fields/TerminalInputAutocompleteController";
import { CollapseBlock } from "../../CollapseBlock/CollapseBlock";
import InputText from "../../Inputs/InputText/InputText";
import { Switcher } from "../../../components";
import { useInitFormState } from "./hooks/useInitFormState";
import { DateInput } from "../../Inputs/DateInput/DateInput";

const createSchema = z.object({
    cell: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
    terminal: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
    client: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
    gng_code: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
    cargo: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
    package: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
    package_count: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }).transform(value => +value),
    is_pallet: z.boolean({ required_error: 'Поле обязательно' }),
    pallet_count: z.string().optional().transform(value => +value),
    gross: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }).transform(value => +value),
    eta_checkout: z.string().optional(),
});

const updateSchema = z.object({
    cell: z.string().optional(),
    terminal: z.number().optional(),
    client: z.number().optional(),
    gng_code: z.string().optional(),
    cargo: z.string().optional(),
    package: z.string().optional(),
    package_count: z.string().optional().transform(value => +value),
    is_pallet: z.boolean().optional(),
    pallet_count: z.string().optional().transform(value => +value),
    gross: z.string().optional().transform(value => +value),
    eta_checkout: z.string().optional(),
})

export const CargoForm = ({ isOpen, handleClose, refetchData, entityIds = [] }) => {
    const isSingleUpdate = useMemo(() => entityIds.length === 1, [entityIds]);
    const isBulkUpdate = useMemo(() => entityIds.length > 1, [entityIds]);
    const isCreate = useMemo(() => entityIds.length === 0, [entityIds]);

    const resolver = useMemo(() => isCreate ? zodResolver(createSchema) : zodResolver(updateSchema), [isCreate]);

    const methods = useForm({
        defaultValues: {
            cell: undefined,
            gng_code: '',
            cargo: undefined,
            package: undefined,
            client: undefined,
            package_count: undefined,
            is_pallet: false,
            gross: undefined,
            terminal: undefined,
            eta_checkout: undefined,
        },
        resolver: resolver,
    })

    const { handleCreate, isCreateLoading } = useHandleCreate({ ...methods, handleClose, refetchData });
    const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods, entityIds, handleClose, refetchData });
    useInitFormState({ ...methods, ids: entityIds, isReady: isOpen });

    const { handleSubmit, control, setValue, formState: { errors } } = methods;

    const onSubmit = useCallback((value) => {
        if (isCreate)
            handleCreate(value)
        else
            handleUpdate(value)
    }, [handleCreate, handleUpdate, isCreate]);

    return (
        <DrawerModal
            isOpen={isOpen}
            handleClose={handleClose}
            actions={
                <Button isLoading={isCreateLoading || isUpdateLoading} onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
                    Сохранить
                </Button>
            }
        >
            <Typography variant="subtitle1">{isCreate ? 'Добавление' : 'Редактирование'}</Typography>
            <FormWrapper onSubmit={onSubmit} {...methods}>
                <ClientInputAutocompleteController />
                <TerminalInputAutocompleteController />
                <CollapseBlock defaultOpen={true || !!errors.cell} title={<span>Ячейка</span>}>
                    <Controller
                        name="cell"
                        control={control}
                        render={({ field }) => (
                            <InputText
                                {...field}
                                placeholder="Ячейка"
                                errorMessage={errors?.cell?.message}
                            />
                        )}
                    />
                </CollapseBlock>
                <CollapseBlock defaultOpen={true || !!errors.gng_code} title="Код ГНГ">
                    <Controller
                        name="gng_code"
                        control={control}
                        render={({ field }) => (
                            <InputText
                                {...field}
                                placeholder="Код ГНГ"
                                errorMessage={errors?.gng_code?.message}
                            />
                        )}
                    />
                </CollapseBlock>
                <CollapseBlock defaultOpen={true || !!errors.cargo} title="Груз">
                    <Controller
                        name="cargo"
                        control={control}
                        render={({ field }) => (
                            <InputText
                                {...field}
                                placeholder="Груз"
                                errorMessage={errors?.cargo?.message}
                            />
                        )}
                    />
                </CollapseBlock>
                <CollapseBlock defaultOpen={true || !!errors.package} title="Тара">
                    <Controller
                        name="package"
                        control={control}
                        render={({ field }) => (
                            <InputText
                                {...field}
                                placeholder="Тара"
                                errorMessage={errors?.package?.message}
                            />
                        )}
                    />
                </CollapseBlock>
                <CollapseBlock defaultOpen={true || !!errors.package_count} title="Количество тары">
                    <Controller
                        name="package_count"
                        control={control}
                        render={({ field }) => (
                            <InputText
                                {...field}
                                placeholder="Количество тары"
                                errorMessage={errors?.package_count?.message}
                            />
                        )}
                    />
                </CollapseBlock>
                <CollapseBlock title="Палет" defaultOpen={true || !!errors?.is_pallet?.message}>
                    <Switcher
                        onChecked={(checked) => setValue('is_pallet', checked, { shouldTouch: true })}
                        externalChecked={false}
                        right='Да'
                    />
                </CollapseBlock>
                <CollapseBlock title="Количество палетов" defaultOpen={!!errors?.pallet_count?.message}>
                    <Controller
                        name="pallet_count"
                        control={control}
                        render={({ field }) => (
                            <InputText
                                {...field}
                                placeholder="Количество палетов"
                                errorMessage={errors?.pallet_count?.message}
                            />
                        )}
                    />
                </CollapseBlock>
                <CollapseBlock title="Вес брутто" defaultOpen={true || !!errors.gross}>
                    <Controller
                        name="gross"
                        control={control}
                        render={({ field }) => (
                            <InputText
                                {...field}
                                placeholder="Вес брутто"
                                errorMessage={errors?.gross?.message}
                            />
                        )}
                    />
                </CollapseBlock>
                <CollapseBlock title="Дата вывоза">
                    <Controller
                        name="eta_checkout"
                        control={control}
                        render={({ field }) => (
                            <DateInput
                                {...field}
                                onChange={(value) => setValue('eta_checkout', value, { shouldTouch: true })}
                            />
                        )}
                    />
                </CollapseBlock>
            </FormWrapper>
        </DrawerModal>
    )
}