import { GetContainerRegisterListParams, GetContainerRegisterListResponse } from 'shared/containerRegister/types.js';
import { buildQueryString } from '../../../shared/ui/Filters/utils.js';
import api from '../../api.js';

export const containerRegister = api.injectEndpoints({
	endpoints: (builder) => ({
		getContainerRegisterList: builder.query<GetContainerRegisterListResponse, GetContainerRegisterListParams>({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
						.map(({ id, desc }) => {
							return desc ? `-${id}` : id;
						})
						.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;
				delete queryParams.sorting;

				return {
					url: `/api/container-register/list/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		createRegisterContainer: builder.mutation({
			query: ({ terminalId, clientIds, serviceIds, dateFrom, dateTo }) => {
				console.log('data, ', terminalId, clientIds, serviceIds, dateFrom, dateTo);
				return {
					url: `/api/container-register/batch-create/`,
					method: 'POST',
					body: {
						terminal: terminalId,
						clients: clientIds,
						services: serviceIds,
						date_from: dateFrom,
						date_to: dateTo
					},
				}
			}
		}),
		updateRegisterContainer: builder.mutation({
			query: ({ status, containerRegisters }) => {
				console.log('data, ', status, containerRegisters);
				return {
					url: `/api/container-register/batch-update/`,
					method: 'PUT',
					body: {
						status: status,
						container_registers: containerRegisters
					},
				}
			}
		}),
	}),
});

export const { useGetContainerRegisterListQuery, useLazyGetContainerRegisterListQuery, useCreateRegisterContainerMutation, useUpdateRegisterContainerMutation } = containerRegister;
