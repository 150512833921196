import { InputAutocompleteAsync } from 'newComponents/Inputs/InputAutocomplete/InputAutocompleteAsync';
import { CONTAINER_SIZE } from 'shared/containers/constants';

export const SizeInputAutocomplete = ({ value, resetIsoCode = () => {}, onChange, ...rest }) => {
	const transformOptions = (values) => {
		return Object.values(values).map(({ value, id }) => ({
			label: String(value),
			id: String(id),
		}));
	};

	const handleChange = (value) => {
		resetIsoCode();
		onChange(value);
	};

	const options = transformOptions(CONTAINER_SIZE);

	return (
		<InputAutocompleteAsync
			{...rest}
			fetchFn={() => {}}
			value={value}
			placeholder="Выберите или введите вручную"
			options={options}
			onChange={handleChange}
		/>
	);
};
