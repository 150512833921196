import { useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import './UnnemberedDescrInputAutoComplete.scss';
import { useLazyGetSizesTypesContainersClientQuery } from 'store/newApi/containers/containers';
import { InputAutocompleteAsync } from 'newComponents/Inputs/InputAutocomplete/InputAutocompleteAsync';

type UnnemberedDescrInputAutoCompleteProps = {
	value: any;
	onChange: (value: any) => void;
	clientId: number;
	terminalId: number;
};

export const UnnemberedDescrInputAutoComplete = ({
	value,
	onChange,
	clientId,
	terminalId,
	...rest
}: UnnemberedDescrInputAutoCompleteProps) => {
	// @ts-ignore
	const [trigger, { options = [], loading }] = useLazyGetSizesTypesContainersClientQuery({
		selectFromResult: ({ data }) => ({
			options: data?.results?.map((t) => ({ label: t.size_type, id: t.size_type })),
		}),
	});
	const handleSearch = useCallback(
		(value) => {
			trigger({ clientId, terminalId, search: value });
		},
		[value, trigger],
	);

	useEffect(() => {
		if (value) {
			trigger({ clientId, terminalId });
		}
	}, [value, trigger]);

	return (
		<Box sx={{ width: '100%', display: 'flex', gap: '8px' }}>
			<InputAutocompleteAsync
				{...rest}
				labelText={'Описание'}
				value={value}
				fetchFn={handleSearch}
				placeholder="Выберите размер и тип"
				options={options}
				loading={loading}
				onChange={onChange}
			/>
		</Box>
	);
};
