import { Button } from "@mui/material";
import { primary } from "../../../../../../../assets/styles/colors";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LINK_CONTAINER_BY_ID } from "shared/containers/pageUrls";
import { Link } from "react-router-dom";

type LinkedContainerItemProps = {
    id: number;
    containerNumber: string;
    onRemove: (id: number) => void;
    storagePrice: number;
    disabledEdit: boolean;
}


export const LinkedContainerItem = ({ id, containerNumber, onRemove, storagePrice, disabledEdit }: LinkedContainerItemProps) => {
    return (
        <div style={{ display: 'flex', gap: '3px', alignItems: 'stretch' }}>
            {!disabledEdit && <Button variant="outlined" color="primary" size="medium" onClick={() => onRemove(id)}>
                <DeleteOutlineIcon />
            </Button>}
            <Link to={LINK_CONTAINER_BY_ID(id)} target="_blank" style={{
                border: `2px solid ${primary[40]}`,
                borderRadius: '12px',
                padding: '5px 16px',
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                textDecoration: 'underline',
                color: '#5856D6'
            }}>
                <span>{`№ ${containerNumber}`}</span>
                {!!storagePrice && <span style={{ fontSize: '14px' }}>{`Хранение: ${storagePrice} ₽`}</span>}
            </Link>
        </div>
    )
}