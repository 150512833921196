import { GetIsoCodesListParams, GetIsoCodesListResponse } from 'shared/isoCodes/types.js';
import { buildQueryString } from '../../../shared/ui/Filters/utils.js';
import api from '../../api.js';

export const isoCodes = api.injectEndpoints({
	endpoints: (builder) => ({
		getIsoCodesList: builder.query<GetIsoCodesListResponse, GetIsoCodesListParams>({
			query: (params) => {
				let queryParams = { ...params };

				return {
					url: `/api/containers/iso-code/list/?exclude_duplicates=true&${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetIsoCodesListQuery, useLazyGetIsoCodesListQuery } = isoCodes;
