import { useEffect } from 'react';
import { handleFindWorker } from '../../../../shared/empls/utils';

export const useInitFormState = ({ data, isCreate, ...methods }) => {
	const { setValue } = methods;

	useEffect(() => {
		if (data) {
			if (data?.container) {
				setValue('container', `${data?.container?.container_number} ${data?.container?.stock ?? ''}`);
			}
			if (data?.vehicle_driver) {
				setValue('vehicle_driver', data?.vehicle_driver);
			}
			if (data?.vehicle) {
				setValue('vehicle', data?.vehicle);
			}
			if (data?.vehicle_trailer_number) {
				setValue('vehicle_trailer_number', data?.vehicle_trailer_number);
			}
			if (data?.seal_number) {
				setValue('seal_number', data?.seal_number);
			}
			if (data?.assignees) {
				setValue('assignee', handleFindWorker({ assignees: data?.assignees, type: 0, item: 'worker' }));
			}
			if (data?.order) {
				setValue('order', data?.order);
			}
		}
	}, [data, setValue]);
};
