import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
import InputText from '../InputText/InputText';
import CircularProgress from '@mui/material/CircularProgress';
import { InputAdornment } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';

function useDebounce(value: string, delay: number) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}

export interface Option {
	label: string;
	id: string | number;
}

interface MultiSelectAutocompleteProps {
	fetchOptions: (query: string) => void;
	options: Option[];
	value: Option[];
	onChange: (selectedValues: Option[]) => void;
	label?: string;
	placeholder?: string;
	loading?: boolean;
}

function MultiSelectAutocomplete({
	fetchOptions,
	options,
	value,
	onChange,
	label,
	placeholder,
	loading = false,
}: MultiSelectAutocompleteProps) {
	const [inputValue, setInputValue] = useState('');
	const debouncedInputValue = useDebounce(inputValue, 800);

	const [opened, setOpened] = useState(false);

	const handleOpen = (event) => {
		setOpened(true);
	};

	const handleClose = () => {
		setOpened(false);
	};

	useEffect(() => {
		fetchOptions(debouncedInputValue);
	}, [debouncedInputValue, fetchOptions]);

	const handleInputChange = (_: unknown, newInputValue: string) => {
		setInputValue(newInputValue);
	};

	return (
		<Autocomplete
			sx={(theme) => ({
				display: 'inline-block',
				flexGrow: 1,
				'& .MuiAutocomplete-inputRoot': {
					height: '100%',
					width: '100%',
					padding: 0,
					paddingRight: '0 !important',
					color: '#000',
				},
			})}
			multiple
			options={options}
			value={value}
			onOpen={handleOpen}
			onClose={handleClose}
			onChange={(_, newValue) => onChange(newValue)}
			inputValue={inputValue}
			onInputChange={handleInputChange}
			getOptionLabel={(option) => option.label}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			loading={loading}
			renderInput={(params) => {
				return (
					<div ref={params.InputProps.ref} style={{ position: 'relative' }}>
						{/* @ts-ignore */}
						<InputText
							{...params}
							value={value}
							placeholder={placeholder}
							height='auto'
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<InputAdornment position="end">
										<ArrowForwardIos
											style={{
												width: 16,
												height: 16,
												// @ts-ignore
												color: (theme) => theme.palette.text.secondary,
												transform: opened ? 'rotate(90deg)' : 'rotate(0deg)',
												transition: 'transform 0.3s ease',
												cursor: 'pointer',
											}}
										/>
									</InputAdornment>
								),
							}}
						/>
					</div>
				);
			}}
		/>
	);
}

export default MultiSelectAutocomplete;
