import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material';
import { account, accountGridItemHalf } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatPrice } from '../../../../helpers/formatText';
import Preloader from '../../../../components/Preloader/Preloader';
import { useLazyGetRepackingOrdersListQuery } from '../../../../store/newApi/service/service.js';
 
import { formatDate, formatInitialDate } from '../../../../helpers/formatDate.js';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/repack.js';
import { FILTER_TYPES } from '../../../../config/filter';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { RepackActCard } from '../../../../entities/repack/ui/RepackActCard/RepackActCard';
import { Filters } from '../../../../shared/ui/Filters/Filters';

function AccountEmplRepack() {
	const dispatch = useDispatch();
	const filters = useSelector((state) => state['repackRtk']?.filter) ?? {};
	const [trigger, { data, isLoading }] = useLazyGetRepackingOrdersListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const [filtering, setFiltering] = useState(false);

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ ...filters, page: 1, page_size: 20 });
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		trigger({ ...filters, page: 1, page_size: 20, uniq: Math.random() });
		setCurrentPage(1);
		setFiltering(true);
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 20 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data.results];
				} else return [...prevData, ...data.results];
			});
			setFetching(false);
		}
	}, [data]);

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Перетарка'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium">
						Добавить заявку
					</Button>,
				]}

				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'status',
								title: 'Статус',
								type: FILTER_TYPES.CHECKBOX,
								elements: [
									{ value: 0, label: 'Создана' },
									{ value: 1, label: 'Подтверждена' },
									{ value: 2, label: 'В работе' },
									{ value: 3, label: 'Завершена' },
								],
							},
							{
								name: 'terminal_id',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							{
								name: ['cargos_count_gte', 'cargos_count_lte'],
								title: 'Кол-во грузов',
								text: 'кол-во',
								type: FILTER_TYPES.INPUTNUMRANGE,
								elements: [],
							},
							{
								name: ['operations_count_gte', 'operations_count_lte'],
								title: 'Кол-во операций',
								text: 'кол-во',
								type: FILTER_TYPES.INPUTNUMRANGE,
								elements: [],
							},
							{
								name: ['total_price_gte', 'total_price_lte'],
								title: 'Цена',
								text: 'цена',
								type: FILTER_TYPES.INPUTNUMRANGE,
								elements: [],
							},
							{
								name: ['created_at_after', 'created_at_before'],
								title: 'Дата создания',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
							{
								name: ['completed_at_after', 'completed_at_before'],
								title: 'Дата завершения',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
						]}
					/>
				}
			/>
			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}> 
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container sx={account.gridContainer}>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemHalf(isDesktop)} key={index}>
									<RepackActCard
										id={card?.service_order}
										status={card?.status_display}
										colorStatus={'primary'}
										client={card?.client}
										terminal={card?.terminal_display}
										totalPrice={formatPrice(card?.total_price)}
										dateComplited={card?.status_display == 'Завершена' ? formatDate(card?.completed_at) : '-'}
										dateCreated={card?.created_at ? formatInitialDate(card?.created_at) : '-'}
										cargosCount={card?.cargos_count}
										operationsCount={card?.operations_count}
									/>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplRepack;
