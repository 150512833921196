import { InputAutocompleteAsync } from 'newComponents/Inputs/InputAutocomplete/InputAutocompleteAsync';
import { useLazyGetRepairTasksQuery } from '../../../store/newApi/service/service';
import { useCallback, useEffect } from 'react';

export const RepairTasksInputAutocomplete = ({ searchParams, ...rest }) => {
	const [handleFetch, { repairTasksOptions = [] }] = useLazyGetRepairTasksQuery(
		{
			selectFromResult: ({ data }) => ({
				repairTasksOptions: data?.results?.map((t) => ({ label: `${t.repair_component_name}: ${t.name}`, id: t.id })) || [],
			}),
		},
	);

	const handleSearch = useCallback(
		(value) => {
			handleFetch({ search: value, ...searchParams });
		},
		[handleFetch, searchParams],
	);

	useEffect(() => {
		handleFetch({ ...searchParams });
	}, [searchParams, handleFetch]);

	return (
		<InputAutocompleteAsync {...rest} fetchFn={handleSearch} placeholder="Выберите или введите вручную" options={repairTasksOptions} />
	);
};
