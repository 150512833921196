import React, { useState } from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { OrdersInputAutocomplete } from 'newComponents/Form/Fields/OrdersInputAutocomplete';

type InputDropdownGroupOrdersProps = {
    name: string;
	title: string;
	state: any;
    onChange: (name, value) => void;
};

export const InputDropdownGroupOrders = ({ name, title, state, onChange } : InputDropdownGroupOrdersProps ) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleOpen = () => {
		setIsOpen(!isOpen);
	};

	const handleChange = (value) => {
		if (onChange) {
			onChange(name, value);
		}
	}; 

	return (
		<Box>
			<Box sx={{ display: 'flex', alignItems: 'center', '&:hover': { cursor: 'pointer' } }} onClick={toggleOpen}>
				{/* @ts-ignore */}
				<Typography variant="alertTitle" style={{ flexGrow: 1 }}>
					{title}
				</Typography>
				<IconButton>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
			</Box>
			{isOpen && (
				<Box
					sx={{
						'& .MuiAutocomplete-root': {
							display: 'block',
						},
					}}
				>
					<OrdersInputAutocomplete value={state} onChange={handleChange} />
				</Box>
			)}
		</Box>
	);
};