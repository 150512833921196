import { Controller } from 'react-hook-form';
import { FormWrapper } from '../../../../../../newComponents/Form/FormWrapper';
import styles from '../AccountEmplContainer.module.css';
import { TerminalInputAutocomplete } from '../../../../../../newComponents/Form/Fields/TerminalInputAutocomplete';
import { Typography } from '@mui/material';
import { isClient } from '../../../../../../shared/auth/isClient';
import { ClientInputAutocomplete } from '../../../../../../newComponents/Form/Fields/ClientInputAutocomplete';
import InputText from '../../../../../../newComponents/Inputs/InputText/InputText';
import { LineInputAutocomplete } from '../../../../../../newComponents/Form/Fields/LineInputAutocomplete';
import { Switcher } from '../../../../../../components';
import { IsoCodeInputAutoComplete } from 'newComponents/Form/Fields/IsoCodeInputAutoComplete/IsoCodeInputAutoComplete';
import { useLazyGetIsoCodesListQuery } from 'store/newApi/isoCodes/isoCodes';
import { useEffect } from 'react';
import { TypeInputAutocomplete } from 'newComponents/Form/Fields/TypeInputAutocomplete';
import { SizeInputAutocomplete } from 'newComponents/Form/Fields/SizeInputAutocomplete';

type ContainerDetailProps = {
	onSubmit: (value: any) => void;
};

export const ContainerDetail = ({ onSubmit, ...methods }: ContainerDetailProps) => {
	const {
		// @ts-ignore
		control, watch, setValue, formState: { errors },
	} = methods;

	const isFilled = watch('isFilled');
	const isDangerous = watch('isDangerous');
	const isDamaged = watch('isDamaged');
	const isBlocked = watch('isBlocked');
	const isoCodeValue = watch('isoCode');
	const [fetchIsoCodes, { data: dataIsoCodes }] = useLazyGetIsoCodesListQuery();

	useEffect(() => {
		if (isoCodeValue) {
			fetchIsoCodes({ iso_id: isoCodeValue });
		}
	}, [isoCodeValue]);

	useEffect(() => {
		if (dataIsoCodes && isoCodeValue) {
			setValue('size', `${dataIsoCodes.results[0]?.size}`)
			setValue('type', `${dataIsoCodes.results[0]?.type}`)
		}
	}, [dataIsoCodes, isoCodeValue]);

	const handleResetIsoCode = () => {
		setValue('isoCode', '')
	}

	return (
		<FormWrapper onSubmit={onSubmit} {...methods}>
			<div className={styles.form}>
				<Controller
					name="terminal"
					control={control}
					render={({ field }) => (
						<>
							<TerminalInputAutocomplete {...field} labelText="Терминал" placeholder="Выберите или введите вручную" />
							{errors?.terminal && (
								<Typography color="error" variant="caption">
									{errors?.terminal?.message}
								</Typography>
							)}
						</>
					)}
				/>
				{!isClient() && (
					<Controller
						name="client"
						control={control}
						render={({ field }) => (
							<>
								<ClientInputAutocomplete {...field} labelText="Клиент" />
								{errors?.client && (
									<Typography color="error" variant="caption">
										{errors?.client?.message}
									</Typography>
								)}
							</>
						)}
					/>
				)}
				<Controller
					name="containerNumber"
					control={control}
					render={({ field }) => (
						<>
							{/* @ts-ignore  */}
							<InputText {...field} placeholder="Введите номер" labelText="Номер контейнера" />
							{errors?.containerNumber && (
								<Typography color="error" variant="caption">
									{errors?.containerNumber?.message}
								</Typography>
							)}
						</>
					)}
				/>
				<Controller
					name="stock"
					control={control}
					render={({ field }) => (
						<>
							{/* @ts-ignore  */}
							<InputText {...field} disabled={isClient()} placeholder="Введите текст" labelText="Сток" />
							{errors?.stock && (
								<Typography color="error" variant="caption">
									{errors?.stock?.message}
								</Typography>
							)}
						</>
					)}
				/>
				<Controller
					name="location"
					control={control}
					render={({ field }) => (
						<>
							{/* @ts-ignore  */}
							<InputText {...field} placeholder="Введите текст" labelText="Место" />
							{errors?.location && (
								<Typography color="error" variant="caption">
									{errors?.location?.message}
								</Typography>
							)}
						</>
					)}
				/>
				<Controller
					name="bookingNumber"
					control={control}
					//  @ts-ignore
					render={({ field }) => <InputText {...field} placeholder="Введите номер" labelText="Букинг" />}
				/>

				<Controller
					name="isoCode"
					control={control}
					render={({ field }) => (
						<>
							<IsoCodeInputAutoComplete {...field} labelText="Код" />
							{errors?.isoCode && (
								<Typography color="error" variant="caption">
									{errors?.isoCode?.message}
								</Typography>
							)}
						</>
					)}
				/>
				<Controller
					name="line"
					control={control}
					render={({ field }) => (
						<>
							<LineInputAutocomplete {...field} labelText="Линия" placeholder="Выберите" />
							{errors?.line && (
								<Typography color="error" variant="caption">
									{errors?.line?.message}
								</Typography>
							)}
						</>
					)}
				/>
				<Controller
					name="type"
					control={control}
					render={({ field }) => (
						<>
							<TypeInputAutocomplete {...field} resetIsoCode={handleResetIsoCode} labelText="Тип" />
							{errors?.type && (
								<Typography color="error" variant="caption">
									{errors?.type?.message}
								</Typography>
							)}
						</>
					)}
				/>

				<Controller
					name="size"
					control={control}
					render={({ field }) => (
						<>
							<SizeInputAutocomplete {...field} resetIsoCode={handleResetIsoCode} labelText="Размер" />
							{errors?.size && (
								<Typography color="error" variant="caption">
									{errors?.size?.message}
								</Typography>
							)}
						</>
					)}
				/>
			</div>

			<div style={{ display: 'flex', flexDirection: 'column', gap: '12px', padding: '16px' }}>
				{/* @ts-ignore */}
				<Switcher
					onChecked={(checked) => setValue('isBlocked', checked, { shouldTouch: true })}
					externalChecked={isBlocked}
					right="Заблокирован"
				/>
				{/* @ts-ignore */}
				<Switcher
					onChecked={(checked) => setValue('isFilled', checked, { shouldTouch: true })}
					externalChecked={isFilled}
					right="Заполнен"
				/>
				{/* @ts-ignore */}
				<Switcher
					onChecked={(checked) => setValue('isDangerous', checked, { shouldTouch: true })}
					externalChecked={isDangerous}
					right="Опасный груз"
				/>
				{/* @ts-ignore */}
				<Switcher
					onChecked={(checked) => setValue('isDamaged', checked, { shouldTouch: true })}
					externalChecked={isDamaged}
					right="Поврежден"
				/>
			</div>
		</FormWrapper>
	);
};
