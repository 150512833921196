export const IN_REPORT = 'Приём';
export const OUT_REPORT = 'Вывоз';

export const ENTRYREPORT_TYPE = {
    IN: {
        id: 1,
        title: IN_REPORT
    },
    OUT: {
        id: 2,
        title: OUT_REPORT
    }
}

export const ENTRYREPORT_TYPE_TEXT = {
    [ENTRYREPORT_TYPE.IN.id]: 'Прием контейнера',
    [ENTRYREPORT_TYPE.OUT.id]: 'Выдача контейнера'
}

export const ENTRYREPORT_STATUSES = {
    DRAFT: {
        id: 1
    },
    ACCESSED: {
        id: 2
    }
}