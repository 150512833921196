export const CONTAINER_SIZE = {
	TWENTY: { value: 20, id: 1 },
	FORTY: { value: 40, id: 2 },
	FORTYFIVE: { value: 45, id: 3 },
};

export const CONTAINER_SIZES_BY_VALUE = {
	20: 1,
	40: 2,
	45: 3,
};

export const CONTAINER_TYPE = {
	DC: { value: 'DC', id: 0 },
	Diesel: { value: 'Diesel', id: 1 },
	FlatRack: { value: 'FlatRack', id: 2 },
	HC: { value: 'HC', id: 3 },
	HCBulk: { value: 'HC Bulk', id: 4 },
	HCFlatRack: { value: 'HC FlatRack', id: 5 },
	HCIsotherm: { value: 'HC Isotherm', id: 6 },
	HCOT: { value: 'HC OT', id: 7 },
	HCPW: { value: 'HC PW', id: 8 },
	HCPWReefer: { value: 'HC PW Reefer', id: 9 },
	HCReefer: { value: 'HC Reefer', id: 10 },
	Isotherm: { value: 'Isotherm', id: 11 },
	OT: { value: 'OT', id: 12 },
	Office: { value: 'Office', id: 13 },
	PW: { value: 'PW', id: 14 },
	Reefer: { value: 'Reefer', id: 15 },
	Rmcoil: { value: 'Rmcoil', id: 16 },
	SHC: { value: 'SHC', id: 17 },
	TN: { value: 'TN', id: 18 },
};

export const CONTAINER_TYPE_FILTER = [
	{ label: 'DC', value: 0 },
	{ label: 'Diesel', value: 1 },
	{ label: 'FlatRack', value: 2 },
	{ label: 'HC', value: 3 },
	{ label: 'HC Bulk', value: 4 },
	{ label: 'HC FlatRack', value: 5 },
	{ label: 'HC Isotherm', value: 6 },
	{ label: 'HC OT', value: 7 },
	{ label: 'HC PW', value: 8 },
	{ label: 'HC PW Reefer', value: 9 },
	{ label: 'HC Reefer', value: 10 },
	{ label: 'Isotherm', value: 11 },
	{ label: 'OT', value: 12 },
	{ label: 'Office', value: 13 },
	{ label: 'PW', value: 14 },
	{ label: 'Reefer', value: 15 },
	{ label: 'Rmcoil', value: 16 },
	{ label: 'SHC', value: 17 },
	{ label: 'TN', value: 18 },
];

export const CONTAINER_SIZE_FILTER = [
	{ value: 1, label: '20' },
	{ value: 2, label: '40' },
	{ value: 3, label: '45' },
];

export const CONTAINER_STATUS_FILTER = [
	{ value: 0, label: 'Без заявки' },
	{ value: 1, label: 'Ожидается' },
	{ value: 2, label: 'На терминале' },
	{ value: 3, label: 'На ремонте' },
	{ value: 4, label: 'Ожидает вывоза' },
	{ value: 5, label: 'Вывезен' },
];

export const CONTAINER_PARAMETER_FILTER = [
	{ value: 0, label: 'Поврежден' },
	{ value: 1, label: 'Опасный груз' },
	{ value: 2, label: 'Заполнен' },
];
