import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import darkTheme from './assets/styles/darkTheme';
import { ThemeProvider } from '@mui/material/styles';
import './assets/styles/mainStyles.scss';
import AuthClient from './pages/Login/Auth/AuthClient/AuthClient';
import AuthEmpl from './pages/Login/Auth/AuthEmpl/AuthEmpl';
import Recovery from './pages/Login/Auth/AuthEmpl/Recovery';
import Landing from './pages/Landing/Landing';
import RequireAuth from './hoc/RequireAuth';
import { useDispatch } from 'react-redux';
import { getGroupsUsers } from './store/actions/groupsUsers';
import AccountEmplMain from './pages/Account/AccountEmpl/pages/AccountEmplMain/AccountEmplMain';
import AccountEmplEmpls from './pages/Account/AccountEmpl/pages/AccountEmplEmpls';
import AccountEmplClients from './pages/Account/AccountEmpl/pages/AccountEmplClients';
import AccountEmplContainers from './pages/Account/AccountEmpl/pages/AccountEmplContainers';
import AccountEmplTransporters from './pages/Account/AccountEmpl/pages/AccountEmplTransporters';
import AccountEmplOrders from './pages/Account/AccountEmpl/pages/AccountEmplOrders';
import Layout from './pages/Account/Layout/Layout';
import AccountEmplTerminals from './pages/Account/AccountEmpl/pages/AccountEmplTerminals';
import AccountEmplJobTitle from './pages/Account/AccountEmpl/pages/AccountEmplJobTitle'; 
import AccountEmplEntriesList from './pages/Account/AccountEmpl/pages/AccountEmplEntriesList';
import AccountEmplTableLogs from './pages/Account/AccountEmpl/pages/AccountEmplTableLogs';
import RequireCheckRights from './hoc/RequireCheckRights';
import AccountEmplTableVehicles from './pages/Account/AccountEmpl/pages/AccountEmplTableVehicles';
import AccountEmplSummary from './pages/Account/AccountEmpl/pages/AccountEmplSummary';
import AccountEmplTableHistoryVehicles from './pages/Account/AccountEmpl/pages/AccountEmplTableHistoryVehicles';
import AccountEmplTableTariffs from './pages/Account/AccountEmpl/pages/AccountEmplTableTariffs';
import AccountEmplTableRepairTasks from './pages/Account/AccountEmpl/pages/AccountEmplTableRepairTasks';
import AccountEmplRepair from './pages/Account/AccountEmpl/pages/AccountEmplRepair';
import AccountEmplInspection from './pages/Account/AccountEmpl/pages/AccountEmplInspection';
import AccountEmplTableCargos from './pages/Account/AccountEmpl/pages/AccountEmplTableCargos';
import AccountEmplTableStorage from './pages/Account/AccountEmpl/pages/AccountEmplTableStorage';
import AccountEmplRepack from './pages/Account/AccountEmpl/pages/AccountEmplRepack';
import { AccountEmplRepairCreate } from './pages/Account/AccountEmpl/pages/AccountEmplRepairCreate/AccountEmplRepairCreate';
import AccountEmplEntryPage from './pages/Account/AccountEmpl/pages/AccountEmplEntryPage';
import { CreateOrderPage } from './pages/Account/AccountEmpl/pages/CreateOrderPage/CreateOrderPage';
import { CreateInspectionPage } from './pages/Account/AccountEmpl/pages/CreateInspectionPage/CreateInspectionPage';
import { AccountEmplOneClient } from './pages/Account/AccountEmpl/pages/AccountEmplOneOrg/AccountEmplOneClient';
import { AccountEmplOneTransporter } from './pages/Account/AccountEmpl/pages/AccountEmplOneOrg/AccountEmplOneTransporter';
import { AccountEmplOneEmpl } from './pages/Account/AccountEmpl/pages/AccountEmplOneEmpl/AccountEmplOneEmpl';
import { AccountEmplOneGroup } from './pages/Account/AccountEmpl/pages/AccountEmplOneGroup/AccountEmplOneGroup';
import { AccountEmplOneTerminal } from './pages/Account/AccountEmpl/pages/AccountEmplOneTerminal/AccountEmplOneTerminal';
import { ClientOrganizationsList } from './pages/Account/AccountEmpl/pages/ClientOrganizationsList/ClientOrganizationsList';
import { ClientOrganizationInfo } from './pages/Account/AccountEmpl/pages/ClientOrganizationInfo/ClientOrganizationInfo';
import { RegistrationClient } from './pages/Login/Reg/RegistrationClient/RegistrationClient';
import { AccountEmplContainer } from './pages/Account/AccountEmpl/pages/AccountEmplContainer/AccountEmplContainer';
import { AccountEmplLegalEntities } from 'pages/Account/AccountEmpl/pages/AccountEmplLegalEntities';
import { AccountEmplLegalEntity } from 'pages/Account/AccountEmpl/pages/AccountEmplLegalEntity/AccountEmplLegalEntity';
import { AccountEmplTableExtraServices } from 'pages/Account/AccountEmpl/pages/AccountEmplTableExtraServices';

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}, []);

	useEffect(() => {
		if (
			window.location.href === 'http://localhost:3000/adminReg' ||
			window.location.href === 'http://localhost:3000/accountEmpl/empls'
		) {
			dispatch(getGroupsUsers());
		}
	}, [dispatch]);

	return (
		<ThemeProvider theme={darkTheme}>
			<Router>
				<div className="App">
					<Routes>
						{/* главная страница */}
						<Route path={'/'} element={<Landing />} />

						{/* авторизации */}
						<Route path={'/authClient'} element={<AuthClient />} />
						<Route path={'/authEmpl'} element={<AuthEmpl />} />
						<Route path={'/recoveryEmpl'} element={<Recovery />} />

						{/* регистрации */}
						<Route path={'/regClient'} element={<RegistrationClient />} />

						{/* личный кабинет клиента */}
						<Route
							path="/accountClient/*"
							element={
								<RequireAuth userType={2} link="/authClient">
									<Layout />
								</RequireAuth>
							}
						>
							<Route index path="main" element={<AccountEmplMain />} />
							<Route path="summary" element={<AccountEmplSummary />} />
							<Route path="containers" element={<AccountEmplContainers />} />
							<Route path="containers/:containerId" element={<AccountEmplContainer />} />
							<Route path="company" element={<ClientOrganizationsList />} />
							<Route path="company/:orgId" element={<ClientOrganizationInfo />} />
							<Route path="cargos" element={<AccountEmplTableCargos />} />
							<Route path="orders" element={<AccountEmplOrders />} />
							<Route path="orders/create" element={<CreateOrderPage />} />
							<Route path="orders/:terminalId/:orderId" element={<CreateOrderPage />} />
							<Route path="entries" element={<AccountEmplEntriesList />} />
							<Route path="historyEntries" element={<AccountEmplTableHistoryVehicles />} />
							<Route path="entry/:orderId/:count" element={<AccountEmplEntryPage />} />
							<Route path="entry/:entryId" element={<AccountEmplEntryPage />} />
							<Route path="entry" element={<AccountEmplEntryPage />} />
							<Route path="historyEntries" element={<AccountEmplTableHistoryVehicles />} />
							<Route path="inspection" element={<AccountEmplInspection />} />
							<Route path="inspection/:inspectionId" element={<CreateInspectionPage />} />
							<Route path="storage" element={<AccountEmplTableStorage />} />
							<Route path="repair" element={<AccountEmplRepair />} />
							<Route path="createRepair" element={<AccountEmplRepairCreate />} />
							<Route path="createRepair/:repairOrderId" element={<AccountEmplRepairCreate />} />
							<Route path="repack" element={<AccountEmplRepack />} />
							<Route path="repairTasks" element={<AccountEmplTableRepairTasks />} />
							<Route path="tariffs" element={<AccountEmplTableTariffs />} />
							<Route path="transporters" element={<AccountEmplTransporters />} />
							<Route path="transporters">
								<Route path=":transporterId" element={<AccountEmplOneTransporter />} />
								<Route path="create" element={<AccountEmplOneTransporter />} />
							</Route>
							<Route path="vehicles" element={<AccountEmplTableVehicles />} />
							<Route path="empls" element={<AccountEmplEmpls />} />
							<Route path="empls">
								<Route path=":userId" element={<AccountEmplOneEmpl />} />
								<Route path="create" element={<AccountEmplOneEmpl />} />
							</Route>
							<Route path="createJob" element={<AccountEmplJobTitle />} />
							<Route path="tableLogs" element={<AccountEmplTableLogs />} />
							<Route path="terminals" element={<AccountEmplTerminals />} /> 
						</Route>

						{/* личный кабинет работника */}
						<Route
							path="/accountEmpl/*"
							element={
								<RequireAuth userType={1} link="/authEmpl">
									<Layout />
								</RequireAuth>
							}
						>
							<Route index path="main" element={<AccountEmplMain />} />

							<Route
								path="summary"
								element={
									<RequireCheckRights path="summary" type="view">
										<AccountEmplSummary />
									</RequireCheckRights>
								}
							/>

							<Route
								path="containers"
								element={
									<RequireCheckRights path="containers" type="view">
										<AccountEmplContainers />
									</RequireCheckRights>
								}
							/>
							<Route path="containers/:containerId" element={<AccountEmplContainer />} />
							<Route path="cargos" element={<AccountEmplTableCargos />} />

							<Route
								path="orders"
								element={
									<RequireCheckRights path="orders" type="view">
										<AccountEmplOrders />
									</RequireCheckRights>
								}
							/>
							<Route path="orders/create" element={<CreateOrderPage />} />
							<Route path="orders/:terminalId/:orderId" element={<CreateOrderPage />} />

							<Route
								path="entries"
								element={
									<RequireCheckRights path="entries" type="view">
										<AccountEmplEntriesList />
									</RequireCheckRights>
								}
							/>
							<Route path="entry/:orderId/:count" element={<AccountEmplEntryPage />} />
							<Route path="entry/:entryId" element={<AccountEmplEntryPage />} />
							<Route path="entry" element={<AccountEmplEntryPage />} />
							<Route
								path="historyEntries"
								element={
									<RequireCheckRights path="historyEntries" type="view">
										<AccountEmplTableHistoryVehicles />
									</RequireCheckRights>
								}
							/>

							<Route path="inspection" element={<AccountEmplInspection />} /> 
							<Route path="inspection/create" element={<CreateInspectionPage />} />
							<Route path="inspection/:inspectionId" element={<CreateInspectionPage />} />

							<Route path="storage" element={<AccountEmplTableStorage />} />
							<Route path="extraservices" element={<AccountEmplTableExtraServices />} />
							<Route path="repair" element={<AccountEmplRepair />} />
							<Route path="createRepair" element={<AccountEmplRepairCreate />} />
							<Route path="createRepair/:repairOrderId" element={<AccountEmplRepairCreate />} />
							<Route path="repack" element={<AccountEmplRepack />} />
							<Route path="repairTasks" element={<AccountEmplTableRepairTasks />} />
							<Route
								path="tariffs"
								element={
									<RequireCheckRights path="tariffs" type="view">
										<AccountEmplTableTariffs />
									</RequireCheckRights>
								}
							/>

							<Route
								path="clients"
								element={
									<RequireCheckRights path="clients" type="view">
										<AccountEmplClients />
									</RequireCheckRights>
								}
							/>
							<Route path="clients">
								<Route path=":clientId" element={<AccountEmplOneClient />} />
								<Route path="create" element={<AccountEmplOneClient />} />
							</Route>

							<Route
								path="transporters"
								element={
									<RequireCheckRights path="transporters" type="view">
										<AccountEmplTransporters />
									</RequireCheckRights>
								}
							/>

							<Route path="transporters">
								<Route path=":transporterId" element={<AccountEmplOneTransporter />} />
								<Route path="create" element={<AccountEmplOneTransporter />} />
							</Route>
							<Route
								path="vehicles"
								element={
									<RequireCheckRights path="transporters" type="view">
										<AccountEmplTableVehicles />
									</RequireCheckRights>
								}
							/>

							<Route
								path="empls"
								element={
									<RequireCheckRights path="empls" type="view">
										<AccountEmplEmpls />
									</RequireCheckRights>
								}
							/>

							<Route path="empls">
								<Route path=":userId" element={<AccountEmplOneEmpl />} />
								<Route path="create" element={<AccountEmplOneEmpl />} />
							</Route>

							<Route
								path="createJob"
								element={
									<RequireCheckRights path="empls" type="view">
										<AccountEmplJobTitle />
									</RequireCheckRights>
								}
							/>

							<Route path="createJob">
								<Route path=":jobId" element={<AccountEmplOneGroup />} />
								<Route path="create" element={<AccountEmplOneGroup />} />
							</Route>

							<Route
								path="tableLogs"
								element={
									<RequireCheckRights path="empls" type="view">
										<AccountEmplTableLogs />
									</RequireCheckRights>
								}
							/>

							<Route
								path="terminals"
								element={
									<RequireCheckRights path="terminals" type="view">
										<AccountEmplTerminals />
									</RequireCheckRights>
								}
							/>

							<Route path="terminals">
								<Route path=":terminalId" element={<AccountEmplOneTerminal />} />
								<Route
									path="create"
									element={
										<RequireCheckRights path="terminals" type="add">
											<AccountEmplOneTerminal />
										</RequireCheckRights>
									}
								/>
							</Route>

							<Route
								path="legal"
								element={
									<RequireCheckRights path="legal" type="view">
										<AccountEmplLegalEntities />
									</RequireCheckRights>
								}
							/>

							<Route path="legal">
								<Route path=":legalEntityId" element={<AccountEmplLegalEntity />} />
								<Route path="create" element={<AccountEmplLegalEntity />} />
							</Route>
						</Route>
					</Routes>
				</div>
			</Router>
		</ThemeProvider>
	);
}

export default App;
