import { Button, useMediaQuery } from '@mui/material';
import Header from '../../../../../newComponents/Header/Header';
import styles from './AccountEmplOneEmpl.module.css';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TabInfo } from './components/TabInfo/TabInfo';
import { TabRights } from './components/TabRights/TabRights';
import { PAGE_EMPLS } from '../../../../../shared/empls/pageUrls';
import { useLazyGetUserByIdQuery } from '../../../../../store/newApi/users/users';
import { TabTerminalRights } from './components/TabTerminal/TabTerminalRights';
import { useGetPermissions } from '../../../../../shared/auth/hooks/useGetPermissions';

export const AccountEmplOneEmpl = () => {
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
	const navigate = useNavigate();
	const { userId } = useParams();
	const { isClient } = useGetPermissions();
	const [getUser, { data: dataUser }] = useLazyGetUserByIdQuery();

	const [currentStep, setCurrentStep] = useState(0);
	const [dataInputs, setDataInputs] = useState({});
	const [currentTerminal, setCurrentTerminal] = useState(null);

	const isUpdate = useMemo(() => !!dataUser, [dataUser]);

	const handleGoBackList = () => {
		navigate(PAGE_EMPLS());
	};

	const handleLinkTerminalRights = (cardId) => {
		setCurrentTerminal(cardId) 
		setCurrentStep(2)
	}

	const renderStep = () => {
		switch (currentStep) {
			case 0:
				return <TabInfo
					data={dataUser ? dataUser : dataInputs}
					isUpdate={isUpdate}
					onBack={handleGoBackList}
					setCurrentStep={setCurrentStep}
					setDataInputs={setDataInputs}
				/>;
			case 1:
				return <TabRights data={dataUser} onBack={handleGoBackList} handleLinkTerminalRights={handleLinkTerminalRights}/>;
			case 2:
				return <TabTerminalRights
					data={dataUser}
					terminal={currentTerminal}
					onBack={() => setCurrentStep(1)}
					successCallback={handleGoBackList}
					value={dataInputs}
				/>;
			default:
				return null;
		}
	};

	useEffect(() => {
		if (userId) {
			getUser(userId);
		}
	}, [userId]);

	return (
		<div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
			<Header text={dataUser ? `Сотрудник ${dataUser.full_name}` : 'Создать сотрудника'} />
			<div className={styles.content}>
				<div className={styles.tabs}>
					<Button
						variant="contained"
						color={currentStep === 0 ? 'secondary' : 'primary'}
						size={isDesktop ? 'medium' : 'small'}
						onClick={() => setCurrentStep(0)}
					>
						Общее
					</Button>
					{!isClient() && <Button
						variant="contained"
						color={currentStep === 1 || currentStep === 2 ? 'secondary' : 'primary'}
						size={isDesktop ? 'medium' : 'small'}
						onClick={() => setCurrentStep(1)}
						disabled={!userId}
					>
						Права
					</Button>}
				</div>
				<div className={styles.steps}>{renderStep()}</div>
			</div>
		</div>
	);
};
