import { IconButton } from '@mui/material';
import { IconPlus } from 'components/Icons';
import InputText from 'newComponents/Inputs/InputText/InputText';
import styles from './ChooseContainerStep.module.css';
import { UnnemberedDescrInputAutoComplete } from 'newComponents/Form/Fields/UnnemberedDescrInputAutoComplete/UnnemberedDescrInputAutoComplete';

type Field = {
	description: string;
	quantity: string;
};

type UnnemberedOrderProps = {
	fields: Field[];
	setFields: (fields: Field[]) => void;
	clientId: number;
	terminalId: number;
};

export const UnnemberedOrder = ({ fields, setFields, clientId, terminalId }: UnnemberedOrderProps) => {
	const handleAddField = () => {
		setFields([...fields, { description: '', quantity: '' }]);
	};

	const handleRemoveField = (ind) => {
		setFields(fields.filter((_, index) => index !== ind));
	};

	const handleChange = (ind, name, value) => {
		setFields(fields.map((field, index) => (index === ind ? { ...field, [name]: value } : field)));
	};

	const isAddButtonDisabled = fields.some((field) => field.description === '' || field.quantity === '');

	return (
		<div>
			<h3>Безномерная выдача</h3>
			<div className={styles.unnemberedOrderBlock}>
				{fields.map((field, ind) => (
					<div key={ind} style={{ display: 'flex', gap: '4px', alignItems: 'center', flexShrink: 0 }}>
						<div>{ind + 1}.</div>
						<div style={{ display: 'flex', gap: '4px', width: '320px', flexShrink: 0 }}>
							<div style={{ width: '100%' }}>
								<UnnemberedDescrInputAutoComplete
									value={field.description}
									onChange={(value) => handleChange(ind, 'description', value)}
									clientId={clientId} terminalId={terminalId}
								/>
								<button style={{ color: '#5C53A7', fontSize: '12px' }} onClick={() => handleRemoveField(ind)}>
									Удалить
								</button>
							</div>
							{/* @ts-ignore */}
							<InputText
								labelText="Количество"
								type="number"
								placeholder="Количество"
								value={field.quantity}
								onChange={(e) => handleChange(ind, 'quantity', e.target.value)}
							/>
						</div>
					</div>
				))}
				<IconButton color="primary" size="large" onClick={handleAddField} disabled={isAddButtonDisabled}>
					{/* @ts-ignore */}
					<IconPlus color={'white'} size={'large'} />
				</IconButton>
			</div>
		</div>
	);
};
