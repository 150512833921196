import { useCallback, useEffect, useMemo, useState } from 'react';
import { ContainerInputAutocomplete } from '../../../../../../../newComponents/Form/Fields/ContainerInputAutocomplete';
import InputText from '../../../../../../../newComponents/Inputs/InputText/InputText';

import styles from './InspectionInfoStep.module.css';
import { useLazyGetContainerByIdQuery } from '../../../../../../../store/newApi/containers/containers';
import { useCreateEntryReportMutation, useUpdateEntryReportMutation } from '../../../../../../../store/newApi/entryreports/entryreports';
import { useNavigate } from 'react-router-dom';
import { INSPECTION_INFO_URL } from '../../../../../../../shared/inspection/pageUrls';
import { PageFooterActions } from '../../../../../../../shared/ui/PageFooterActions/PageFooterActions';
import { AssigneesInputAutocomplete } from '../../../../../../../newComponents/Form/Fields/AssigneesInputAutocomplete';
import { filterAssignees, handleFindWorker } from '../../../../../../../shared/empls/utils';
import { isEmpl } from '../../../../../../../shared/auth/isEmpl';
import { useLazyGetEntryQuery, useUpdateEntryMutation } from 'store/newApi/entries/entries';
import { useNotify } from 'shared/providers/AlertProvider/AlertProvider';
import { EntrypassInputAutocomplete } from 'newComponents/Form/Fields/EntrypassInputAutocomplete';

type InspectionInfoStepProps = {
	containerId: number | null;
	entryReportId?: number;
	orderType: string;
	disableDamages: boolean;
	onBack: () => void;
	refetchEntryReport: ({ id }) => void;
	entryReportDefaultData?: any;
};

export const InspectionInfoStep = ({
	containerId,
	refetchEntryReport,
	entryReportDefaultData,
	onBack,
	orderType,
	entryReportId,
	disableDamages,
}: InspectionInfoStepProps) => {
	const navigate = useNavigate();
	const [currentContainerId, setCurrentContainerId] = useState(null);
	const [managerAssignee, setManagerAssignee] = useState('');
	const [vehicleValue, setVehicle] = useState('');
	const [vehicleDriverValue, setVehicleDriver] = useState('');
	const [selectedEntrypassId, setSelectedEntrypassId] = useState(null);
	const { showNotificaton } = useNotify();

	const [fetchEntry, { data: selectedEntry }] = useLazyGetEntryQuery();
	const [fetchContainer, { data: container }] = useLazyGetContainerByIdQuery();
	const [createEntryReport, { data: entryReportData, isSuccess: createEntryReportSucess, reset: resetCreate }] =
		useCreateEntryReportMutation();
	const [updateEntryReport, { isSuccess: updateEntryReportSuccess, reset: resetUpdate }] = useUpdateEntryReportMutation();
	const [updateEntry] = useUpdateEntryMutation();

	const isEditMode = useMemo(() => !!entryReportId, [entryReportId]);

	useEffect(() => {
		if (containerId) setCurrentContainerId(containerId);
	}, [containerId]);

	useEffect(() => {
		fetchEntry({ entryId: selectedEntrypassId })
	}, [selectedEntrypassId]);

	useEffect(() => {
		if (currentContainerId) fetchContainer({ id: currentContainerId });
	}, [currentContainerId]);

	const handleContainerChoose = useCallback((value: number) => {
		setCurrentContainerId(value);
	}, []);

	const getCurrentOrderType = useMemo(() => (orderType ? +orderType : entryReportDefaultData?.type), [entryReportDefaultData, orderType]);

	const getEntrypass = useMemo(
		() => (container?.entrypass || []).find((pass) => pass.type === getCurrentOrderType) || selectedEntry,
		[container, getCurrentOrderType, selectedEntry],
	);

	console.log(getEntrypass);

	const isEntrypassLinked = useMemo(() => {
		return !!(container?.entrypass || []).find((pass) => pass.type === getCurrentOrderType);
		// return false;
	}, [container]);

	useEffect(() => {
		if (entryReportDefaultData?.assignees) {
			setManagerAssignee(handleFindWorker({ assignees: entryReportDefaultData?.assignees, type: 0, item: 'worker' }));
		}
	}, [entryReportDefaultData]);

	const handleNext = useCallback(() => {
		if (!isEditMode) {
			let requestData = {};
			if (managerAssignee) {
				requestData['set_assignee'] = { worker: managerAssignee, type: 0 };
			}
			if (selectedEntrypassId) {
				requestData['entry_pass'] = selectedEntrypassId;
			}
			createEntryReport({ containerId: currentContainerId, type: +orderType, ...requestData })
				// @ts-ignore
				.then(({ data }) => {
					console.log(data);
					// @ts-ignore
					const entrypass = (data?.container?.entrypass || []).find((pass) => pass.type === data?.type);
					if (vehicleValue || vehicleDriverValue)
						updateEntry({
							terminalId: entrypass?.terminal,
							orderId: entrypass?.order,
							pass_pk: entrypass?.id,
							vehicle_display: vehicleValue,
							vehicle_driver: vehicleDriverValue,
						});
					showNotificaton({ type: 'success', message: 'Акт осмотра успешно создан' });
					// @ts-ignore
					navigate(INSPECTION_INFO_URL(data?.id, disableDamages ? 3 : 1));
					resetCreate();
				});
		} else if (managerAssignee && managerAssignee !== handleFindWorker({ assignees: [], type: 0, item: 'worker' })) {
			updateEntryReport({
				id: entryReportDefaultData?.id,
				set_assignee: {
					worker: managerAssignee,
					type: 0,
				},
			})
				// @ts-ignore
				.then(({ data }) => {
					// @ts-ignore
					refetchEntryReport({ id: data?.id });
					resetUpdate();
					showNotificaton({ type: 'success', message: 'Акт осмотра успешно обновлен' });
				});
			if (vehicleValue || vehicleDriverValue)
				updateEntry({
					terminalId: getEntrypass?.terminal,
					orderId: getEntrypass?.order,
					pass_pk: getEntrypass?.id,
					vehicle_display: vehicleValue,
					vehicle_driver: vehicleDriverValue,
				});
		}
	}, [
		createEntryReport,
		orderType,
		currentContainerId,
		isEditMode,
		entryReportId,
		navigate,
		disableDamages,
		entryReportDefaultData,
		managerAssignee,
		vehicleValue,
		vehicleDriverValue,
		getEntrypass,
		disableDamages,
	]);

	const containerFilters = useMemo(() => {
		if (orderType === '1') return { report_available: true };
		if (orderType === '2') return { report_out_available: true };
		return {};
	}, [orderType]);

	const entrypassFilter = useMemo(() => {
		return { no_container: true };
	}, [])

	const handleChangeEntrypass = useCallback((value) => setSelectedEntrypassId(value), [])

	return (
		<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
			<div className={styles.containerFormTemplate}>
				<div className={styles.item}>
					{!isEditMode && (
						<ContainerInputAutocomplete
							searchParams={containerFilters}
							value={currentContainerId}
							onChange={handleContainerChoose}
							labelText="Контейнер"
						/>
					)}
					{/* @ts-ignore */}
					{isEditMode && <InputText labelText="Контейнер" disabled value={`${container?.container_number} ${container?.stock ?? ''}`} />}
				</div>
				{container && (
					<>
						{!isEntrypassLinked && <EntrypassInputAutocomplete
							disabled={isEntrypassLinked}
							value={selectedEntrypassId}
							searchParams={entrypassFilter}
							onChange={handleChangeEntrypass}
						/>}
						{/* @ts-ignore */}
						<InputText labelText="Тип" disabled value={container.type_display} />
						{/* @ts-ignore */}
						<InputText labelText="Размер" disabled value={container.size_display} />
						{getEntrypass?.vehicle_display && <div className={styles.item}>
							{/* @ts-ignore */}
							<InputText
								labelText="Номер машины"
								disabled={!!getEntrypass?.vehicle_display}
								value={getEntrypass?.vehicle_display}
								onChange={(e) => setVehicle(e.target.value)}
							/>
						</div>}
						{getEntrypass?.vehicle_driver && <div className={styles.item}>
							{/* @ts-ignore */}
							<InputText
								labelText="Водитель"
								disabled={!!getEntrypass?.vehicle_driver}
								value={getEntrypass?.vehicle_driver}
								onChange={(e) => setVehicleDriver(e.target.value)}
							/>
						</div>}
						{container?.terminal && (
							<div className={styles.item}>
								<AssigneesInputAutocomplete
									value={managerAssignee}
									//  @ts-ignore
									onChange={(value) => setManagerAssignee(value)}
									group={3}
									valueName={
										entryReportDefaultData?.assignees
											? handleFindWorker({
													assignees: entryReportDefaultData?.assignees,
													type: 0,
													item: 'assignee_name',
												})
											: ''
									}
									terminal={container?.terminal}
									assigneesHistory={
										entryReportDefaultData?.assignees
											? filterAssignees({ assignees: entryReportDefaultData?.assignees, type: 0 })
											: []
									}
									//  @ts-ignore
									labelText={'Ответственный менеджер'}
								/>
							</div>
						)}
					</>
				)}
			</div>
			<PageFooterActions
				leftBtns={[
					{
						text: 'Назад',
						variant: 'outlined',
						color: 'primary',
						size: 'medium',
						onClick: onBack,
					},
				]}
				rightBtns={
					isEmpl()
						? [
								{
									text: isEditMode ? 'Сохранить' : 'Продолжить',
									variant: 'contained',
									color: 'primary',
									size: 'medium',
									disabled: !currentContainerId,
									onClick: handleNext,
								},
							]
						: []
				}
			/>
		</div>
	);
};
