import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material';
import { account, accountGridItemThird } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import Preloader from '../../../../components/Preloader/Preloader';
import { useLazyGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/terminals.js';
import RequireCheckRights from '../../../../hoc/RequireCheckRights';
import { TerminalCard } from '../../../../entities/terminal/ui/TerminalCard/TerminalCard';
import { Filters } from '../../../../shared/ui/Filters/Filters';
import { CREATE_TERMINAL_URL, TERMINAL_PAGE_URL } from 'shared/terminals/pageUrls';

function AccountEmplTerminals() {
	const dispatch = useDispatch();
	const [trigger, { data, isLoading }] = useLazyGetTerminalsListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const navigate = useNavigate();
	const filters = useSelector((state) => state['terminalsRtk']?.filter) ?? {};
	const [filtering, setFiltering] = useState(false);

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
	const isTablet = useMediaQuery((theme) => theme.breakpoints.up('tablet'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ page: 1, page_size: 20 });
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		trigger({ ...filters, page: 1, page_size: 20, uniq: Math.random() });
		setCurrentPage(1);
		setFiltering(true);
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 20 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data];
				} else return [...prevData, ...data];
			});
			setFetching(false);
		}
	}, [data]);

	const handleCreateTerminalClick = () => {
		navigate(CREATE_TERMINAL_URL());
	};

	const handleNavigate = (id) => {
		navigate(TERMINAL_PAGE_URL(id));
	};

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Терминалы'}
				btnDesktop={[
					<RequireCheckRights path="terminals" type="add">
						<Button variant="contained" color="primary" size="medium" onClick={handleCreateTerminalClick}>
							Добавить терминал
						</Button>
					</RequireCheckRights>,
				]}
				btnMobile={[
					<RequireCheckRights path="terminals" type="add">
						<Button variant="contained" color="primary" size="small" onClick={handleCreateTerminalClick}>
							Добавить
						</Button>
					</RequireCheckRights>,
				]}
				filter={<Filters enabled={false} onSetFilter={handleSetFilter} onResetFilter={handleResetFilter} />}
			/>

			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container sx={account.gridContainer}>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemThird(isDesktop, isTablet)} key={index} onClick={() => handleNavigate(card.id)}>
									<TerminalCard name={card?.name} address={card?.address ? card?.address : '-'} />
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplTerminals;
