import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material';
import { account, accountGridItemThird } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import Preloader from '../../../../components/Preloader/Preloader';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/legalEntities';
import { Filters } from '../../../../shared/ui/Filters/Filters';
import { LegalEntityCard } from '../../../../entities/legalEntities/ui/LegalEntityCard/LegalEntityCard';
import { useLazyGetLegalEntitiesListQuery } from 'store/newApi/legalEntities/legalEntities';
import { LEGAL_PAGE_BY_ID, LEGAL_PAGE_CREATE } from 'shared/legalEntities/pageUrls';

export const AccountEmplLegalEntities = () => {
	const dispatch = useDispatch();
	const [trigger, { data, isLoading }] = useLazyGetLegalEntitiesListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const navigate = useNavigate();
	const filters = useSelector((state) => state['legalEntitiesRtk']?.filter) ?? {};
	const [filtering, setFiltering] = useState(false);
	// @ts-ignore
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop')); 
	// @ts-ignore
	const isTablet = useMediaQuery((theme) => theme.breakpoints.up('tablet'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ page: 1, page_size: 20 });
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		trigger({ ...filters, page: 1, page_size: 20, uniq: Math.random() });
		setCurrentPage(1);
		setFiltering(true);
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 20 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data.results];
				} else return [...prevData, ...data.results];
			});
			setFetching(false);
		}
	}, [data]);

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const handleCreateLegalEntity = () => {
		navigate(LEGAL_PAGE_CREATE())
	};

	return (
		<div className={styles.account__wrap}>
			{/* @ts-ignore  */}
			<Header
				text={'Юридические лица'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium" onClick={handleCreateLegalEntity}>
						Добавить
					</Button>,
				]}
				btnMobile={[
					<Button variant="contained" color="primary" size="medium" onClick={handleCreateLegalEntity}>
						Добавить
					</Button>,
				]}
				// @ts-ignore
				filter={<Filters enabled={false} onSetFilter={handleSetFilter} onResetFilter={handleResetFilter} />}
			/>

			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}>
					{isLoading ? (
						<Box sx={account.preloader}>
							{/* @ts-ignore */}
							<Preloader />
						</Box>
					) : (
						<Grid container sx={account.gridContainer}>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemThird(isDesktop, isTablet)} key={index}>
									<a href={LEGAL_PAGE_BY_ID(card.id)}>
										<LegalEntityCard
											name={card?.full_name_organization}
											inn={card?.inn ? card?.inn : '-'}
											kpp={card?.kpp ? card?.kpp : '-'}
										/>
									</a>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
};
